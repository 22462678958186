import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ACTIVE_GOLD, ControlButton, Icons, MessageOverlay, PlotSkeleton } from '@doseme/cohesive-ui'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import {
  useAdministrationsStore,
  useCourseFeaturesStore,
  useCourseStore,
  useDosingRecommendationStore,
  useHistoricalSimulationStore,
  useObservationsStore
} from '../../../../../../../../hooks/useStore'
import { DosingProfilePlotSelector } from './components/DosingProfilePlotSelector'
import { ModelFitIndicator } from './components/ModelFitIndicator'
import { IDosingPlotPoints, ISecrTrendData, TPlotScale } from './types'
import { IModelDoseRecommendation, TModelType } from '../../../../../../../../store/dosingRecommendation/types'
import { TPlotType } from './components/DosingProfilePlotSelector/types'
import { TCustomType } from '../../types'
import {
  getCurrentPlotObservations,
  getCurrentPlotType,
  getHistoricalPlotTypes,
  getObservationPlotTypes,
  getPredictedPlotPoints,
  getPredictedPlotTypes,
  getNewTargetStartTime,
  getCurrentPlotMetadata,
  getPredictedAdministrations,
  getOldestFutureDateXValue,
  getCurrentExcludedObservations
} from './utils'
import { modelTypeDisplayText } from '../../../../../../../../constants/modelTypes'
import { DosingProfilePlot } from './components/DosingProfilePlot'
import { formatErrorMessage } from '../../../../../../../../utils/errors'

import './index.scss'

interface IProps {
  hospitalTimezone: string
  setShowAddAdminModal: (showAddAdminModal: boolean) => void
  selectedSimulationPanelTab: TModelType
  isIndividualized: boolean
  customTypeTab: TCustomType
  currentSimulation: IModelDoseRecommendation | null
  isCurrentSimulationTabError: boolean
}

export const DosingProfilePanel: React.FC<IProps> = observer((props) => {
  // Historical and predicted (when available) plot points for all simulation types
  const [historicalPlotPoints, setHistoricalPlotPoints] = useState<IDosingPlotPoints | null>(null)
  const [predictedPlotPoints, setPredictedPlotPoints] = useState<IDosingPlotPoints | null>(null)
  const [secrTrendData, setSecrTrendData] = useState<ISecrTrendData | null>(null)

  // These props are used for determining what selection options to show in the plot 'View Options' drop-down menu
  const [currentPlotType, setCurrentPlotType] = useState<TPlotType | null>(null)
  const [availableHistoricalPlotTypes, setAvailableHistoricalPlotTypes] = useState<TPlotType[]>([])
  const [availablePredictedPlotTypes, setAvailablePredictedPlotTypes] = useState<TPlotType[]>([])
  const [availableObservationPlotTypes, setAvailableObservationPlotTypes] = useState<TPlotType[]>([])
  // These state variables track which plots are picked in the plot `View Options` pop-up dropdown menu
  const [individualPlotChecked, individualPlotSetChecked] = useState(false)
  const [populationPlotChecked, populationPlotSetChecked] = useState(false)
  const [customizedPlotChecked, customizedPlotSetChecked] = useState(false)
  const [guidelinePlotChecked, guidelinePlotSetChecked] = useState(false)
  const [excludedLevelChecked, excludedLevelSetChecked] = useState(true)
  const [plotScale, setPlotScale] = useState<TPlotScale>('linear')
  const [targetStartTimeUnix, setTargetStartTimeUnix] = useState<number | undefined>(undefined)

  const historicalSimulationStore = useHistoricalSimulationStore()
  const dosingRecommendationStore = useDosingRecommendationStore()
  const administrationsStore = useAdministrationsStore()
  const observationsStore = useObservationsStore()
  const courseFeaturesStore = useCourseFeaturesStore()
  const courseStore = useCourseStore()

  //Set default plot scale to log for docetaxel
  useEffect(() => {
    if (courseStore.loadState === 'loaded') {
      const plotScale = courseStore.isDocetaxelCourse()
        ? 'log'
        : 'linear'
      setPlotScale(plotScale)
    }
  }, [courseStore.loadState])

  // Handle historical simulations
  useEffect(() => {
    if (!props.isCurrentSimulationTabError && !dosingRecommendationStore.hasPredictedData()) {
      setAvailablePredictedPlotTypes([])
      setPredictedPlotPoints(null)

      const historicalPlotData =
        historicalSimulationStore.historicalSimulationData?.attributes.plotData

      setHistoricalPlotPoints({
        population: historicalPlotData?.population?.plotPoints || [],
        individualized: historicalPlotData?.individualized?.plotPoints || []
      })

      if (historicalPlotData) {
        setAvailableHistoricalPlotTypes(getHistoricalPlotTypes(historicalPlotData))
        setAvailableObservationPlotTypes(getObservationPlotTypes(historicalPlotData.historicalObservations, historicalPlotData.excludedObservations))
        setSecrTrendData(historicalPlotData.secrTrendData)

        const hoursToClear = courseStore.course?.attributes.drugModel.molecule.hoursToClear || 0
        const newTargetStartTime = getNewTargetStartTime(
          historicalPlotData,
          props.hospitalTimezone,
          hoursToClear
        )

        if (newTargetStartTime) {
          setTargetStartTimeUnix(newTargetStartTime)
        }
      }
    }
  }, [historicalSimulationStore.loadState, dosingRecommendationStore.dosingRecommendation])

  // Handle predicted simulations (i.e. dose recommendations)
  useEffect(() => {
    if (!props.isCurrentSimulationTabError) {
      const historicalAndPredictedDosingPlotPoints = getPredictedPlotPoints(
        props.customTypeTab,
        dosingRecommendationStore
      )

      setPredictedPlotPoints(historicalAndPredictedDosingPlotPoints)

      const historicalPlotTypes = getHistoricalPlotTypes(
        historicalSimulationStore.historicalSimulationData?.attributes.plotData
      )

      setAvailableHistoricalPlotTypes(historicalPlotTypes)

      const predictedPlotTypes = getPredictedPlotTypes(
        props.customTypeTab,
        props.isIndividualized,
        dosingRecommendationStore
      )

      setAvailablePredictedPlotTypes(predictedPlotTypes)
    }
  }, [props.selectedSimulationPanelTab, dosingRecommendationStore.loadState])

  useEffect(() => {
    if (!props.isCurrentSimulationTabError) {
      const currentPlotObservations = getCurrentPlotObservations(
        props.selectedSimulationPanelTab,
        historicalSimulationStore,
        dosingRecommendationStore
      )

      const currentExcludedPlotObservations = getCurrentExcludedObservations(
        props.selectedSimulationPanelTab,
        historicalSimulationStore,
        dosingRecommendationStore
      )

      setAvailableObservationPlotTypes(
        getObservationPlotTypes(currentPlotObservations, currentExcludedPlotObservations)
      )

      if (availablePredictedPlotTypes.length) {
        setCurrentPlotType(getCurrentPlotType(availablePredictedPlotTypes, availableHistoricalPlotTypes, props.selectedSimulationPanelTab))
        individualPlotSetChecked(availablePredictedPlotTypes.includes('individualized') && props.selectedSimulationPanelTab === 'indPop')
        populationPlotSetChecked(availablePredictedPlotTypes.includes('population') && props.selectedSimulationPanelTab === 'indPop')
        customizedPlotSetChecked(
          availablePredictedPlotTypes.includes('customized') && ['customDose', 'customTarget'].includes(props.selectedSimulationPanelTab)
        )
        guidelinePlotSetChecked(availablePredictedPlotTypes.includes('guideline') && props.selectedSimulationPanelTab === 'guideline')
      }
    }
  }, [props.selectedSimulationPanelTab, availablePredictedPlotTypes, dosingRecommendationStore.loadState])

  const modelFitIndicator = () => {
    const modelFitStatus = historicalSimulationStore.historicalSimulationData?.attributes.modelFitStatus
    const supportsModelFitStatus = courseStore.course?.attributes.supportsModelFitStatus

    if (
      historicalSimulationStore.loadState === 'initial' ||
      [administrationsStore.loadState, observationsStore.loadState, historicalSimulationStore.loadState].includes(
        'loading'
      ) ||
      [administrationsStore.loadState, observationsStore.loadState, historicalSimulationStore.loadState].includes(
        'updating'
      )
    ) {
      return (
        <div className='header-loading-spinner'>
          <Icons.ThinSpinner strokeWidth={7} stroke={ACTIVE_GOLD} r={15} width='50px' />
        </div>
      )
    }

    return <ModelFitIndicator supportsModelFitStatus={supportsModelFitStatus} modelFitStatus={modelFitStatus} />
  }

  const panelContent = (): JSX.Element | null => {
    const loadStatesToObserve = [
      administrationsStore.loadState,
      observationsStore.loadState,
      courseFeaturesStore.loadState,
      historicalSimulationStore.loadState,
      dosingRecommendationStore.loadState
    ]

    if (loadStatesToObserve.includes('loading') || loadStatesToObserve.includes('updating')) {
      return (
        <MessageOverlay contentXOffsetPx={15} contentYOffsetPx={-50} type='loading' showBlur={false}>
          <PlotSkeleton size='w100' />
        </MessageOverlay>
      )
    }

    const message =
      dosingRecommendationStore.dosingRecommendation[props.selectedSimulationPanelTab]?.attributes.modelResults?.message
        ? dosingRecommendationStore.dosingRecommendation[props.selectedSimulationPanelTab]?.attributes.modelResults?.message[props.selectedSimulationPanelTab]
        : undefined

    if (!props.isCurrentSimulationTabError && message) {
      return (
        <MessageOverlay
          type='info'
          headerText={`${modelTypeDisplayText[props.selectedSimulationPanelTab]} is unavailable`}
          messageText={<div className='panel-error-overlay-text'>{formatErrorMessage(message)}</div>}
        >
          <PlotSkeleton size='w100' />
        </MessageOverlay>
      )
    }

    // Error with the predicted simulation
    if (props.isCurrentSimulationTabError) {
      return (
        <MessageOverlay
          type='error'
          headerText='Failed to load dosing profile'
          messageText={
            <div className='panel-error-overlay-text'>
              {formatErrorMessage(dosingRecommendationStore.error[props.selectedSimulationPanelTab])}
            </div>
          }
          showBlur={false}
          contentYOffsetPx={-40}
        >
          <PlotSkeleton size='w100' />
        </MessageOverlay>
      )
    }

    // Error with the historical simulation
    if (historicalSimulationStore.error) {
      return (
        <MessageOverlay
          type='error'
          headerText='Failed to load dosing profile'
          messageText={
            <div className='panel-error-overlay-text'>{formatErrorMessage(historicalSimulationStore.error)}</div>
          }
          showBlur={false}
          contentYOffsetPx={-40}
        >
          <PlotSkeleton size='w100' />
        </MessageOverlay>
      )
    }

    const plotMetadata = getCurrentPlotMetadata(
      dosingRecommendationStore,
      historicalSimulationStore,
      props.selectedSimulationPanelTab
    )
    const futureDateXValue = getOldestFutureDateXValue(
      plotMetadata,
      dosingRecommendationStore,
      individualPlotChecked,
      populationPlotChecked,
      guidelinePlotChecked,
      customizedPlotChecked
    )
    const predictedAdministrations = getPredictedAdministrations(
      dosingRecommendationStore,
      props.customTypeTab
    )
    const plotObservations = getCurrentPlotObservations(
      props.selectedSimulationPanelTab,
      historicalSimulationStore,
      dosingRecommendationStore
    )
    const plotExcludedObservations = getCurrentExcludedObservations(
      props.selectedSimulationPanelTab,
      historicalSimulationStore,
      dosingRecommendationStore
    )

    const plotContent = (
      <DosingProfilePlot
        drugSpecificData={historicalSimulationStore.historicalSimulationData?.attributes.plotData?.drugSpecificData}
        docetaxelPlot={courseStore.isDocetaxelCourse()}
        historicalPlotPoints={historicalPlotPoints}
        predictedPlotPoints={predictedPlotPoints}
        predictedAdministrations={predictedAdministrations}
        secrTrendData={secrTrendData}
        hospitalTimezone={props.hospitalTimezone}
        targetStartTimeUnix={targetStartTimeUnix}
        showIndividualPredictedPlot={individualPlotChecked}
        showPopulationPredictedPlot={populationPlotChecked}
        showCustomizedPredictedPlot={customizedPlotChecked}
        showGuidelinePredictedPlot={guidelinePlotChecked}
        showExcludedLevels={excludedLevelChecked}
        plotMetadata={plotMetadata}
        plotObservations={plotObservations}
        plotExcludedObservations={plotExcludedObservations}
        futureDateXValue={futureDateXValue}
        plotScale={plotScale}
        micData={
          dosingRecommendationStore.dosingRecommendation[props.selectedSimulationPanelTab]?.attributes.plotData
            ?.micData || null
        }
        historicalAdministrations={
          historicalSimulationStore.historicalSimulationData?.attributes.plotData?.historicalAdministrations || null
        }
        hasPredictedData={
          !!dosingRecommendationStore.dosingRecommendation[props.selectedSimulationPanelTab]?.attributes.plotData
        }
      />
    )

    if (
      !props.isCurrentSimulationTabError &&
      !historicalSimulationStore.historicalSimulationData?.attributes.plotData?.historicalAdministrations?.length &&
      !dosingRecommendationStore.dosingRecommendation[props.selectedSimulationPanelTab]?.attributes.plotData
    ) {
      return (
        <MessageOverlay
          type='warning'
          headerText='No dosing data'
          messageText={
            <>
              <div>
                No recorded doses found. A minimum of 1 administered
                <br />
                dose is required to show the historical plot.
              </div>
              <div className='overlay-message-button'>
                <ControlButton
                  disabled={
                    !!courseStore.course?.attributes.courseArchived || !!courseStore.course?.attributes.isReadOnly
                  }
                  icon={faPlus}
                  size='sm'
                  onClick={() => props.setShowAddAdminModal(true)}
                >
                  Add Dose
                </ControlButton>
              </div>
            </>
          }
          height='300'
        >
          {plotContent}
        </MessageOverlay>
      )
    }

    if (!props.isCurrentSimulationTabError) {
      const plotSelector = (
        <DosingProfilePlotSelector
          currentPlot={currentPlotType}
          availableHistoricalPlots={availableHistoricalPlotTypes}
          availablePredictedPlots={availablePredictedPlotTypes}
          availableObservationPlots={availableObservationPlotTypes}
          individualPlotChecked={individualPlotChecked}
          individualPlotSetChecked={individualPlotSetChecked}
          populationPlotChecked={populationPlotChecked}
          populationPlotSetChecked={populationPlotSetChecked}
          customizedPlotChecked={customizedPlotChecked}
          customizedPlotSetChecked={customizedPlotSetChecked}
          guidelinePlotChecked={guidelinePlotChecked}
          guidelinePlotSetChecked={guidelinePlotSetChecked}
          excludedLevelChecked={excludedLevelChecked}
          excludedLevelSetChecked={excludedLevelSetChecked}
          isSimulationCalculated={props.currentSimulation}
          plotScale={plotScale}
          setPlotScale={setPlotScale}
        />
      )

      return (
        <div className='dosing-profile-plot-container'>
          {plotSelector}
          <div data-testid='dosing-profile-plot'>{plotContent}</div>
        </div>
      )
    }

    return (
      <MessageOverlay contentXOffsetPx={15} contentYOffsetPx={-50} type='loading' showBlur={false}>
        <PlotSkeleton size='w100' />
      </MessageOverlay>
    )
  }

  return (
    <div data-testid='dosing-profile' className='content-panel-with-shadow column-left mb-4'>
      <div className='dosing-profile-header'>
        <div className='content-panel-with-shadow-title d-inline-block'>Dosing Profile</div>
        {modelFitIndicator()}
      </div>
      {panelContent()}
    </div>
  )
})
