import {
  AnalyticsPlotSkeleton,
  BasicList,
  IHeaderItem,
  IRowElement,
  MessageOverlay
} from '@doseme/cohesive-ui'
import React, { useEffect, useMemo } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'

import { IBaseChartProps, IChartDataPoint } from '../../types'
import { useAnalyticsChartMedianHoursAndDosesToTargetStore } from '../../../../hooks/useStore'

import './index.scss'

export const MedianHoursAndDosesToTarget: React.FC<IBaseChartProps> = observer((props) => {
  const medianHoursAndDosesToTargetChartStore = useAnalyticsChartMedianHoursAndDosesToTargetStore()

  useEffect(() => {
    medianHoursAndDosesToTargetChartStore.fetchAnalyticsChart()
  }, [props.hospitalIds, props.moleculeId, props.clinicianFilter, props.drugFilter, props.startDate, props.endDate])

  const columns: IHeaderItem[] = [
    { name: 'Drug Model', className: 'drug-column' },
    { name: 'Median hours to target', className: 'data-column' },
    { name: 'Median doses to target', className: 'data-column' },
    { name: 'Courses to reach target', className: 'data-column' },
    { name: 'Courses to not hit target', className: 'data-column' }
  ]

  const rows: IRowElement[] = useMemo(() => {
    if (medianHoursAndDosesToTargetChartStore.loadState === 'loaded' && medianHoursAndDosesToTargetChartStore.analyticsChartData) {
      const dataPoints = medianHoursAndDosesToTargetChartStore.analyticsChartData.attributes.plotData.dataPoints as IChartDataPoint[]

      return dataPoints.map((row: any) => {
        return {
          id: row.name,
          className: classnames({ 'overall-row': row.name === 'Overall' }),
          columns: [
            {
              name: 'Drug Model', text: row.name,
              className: 'drug-column',
              element: (
                <span className='drug-column'>
                  {row.name}
                </span>
              )
            },
            { name: 'Median hours to target', text: row.medianHoursToTarget, className: 'data-column' },
            { name: 'Median doses to target', text: row.medianDosesToTarget, className: 'data-column' },
            { name: 'Courses to reach target', text: row.numberOfCoursesToTarget, className: 'data-column' },
            { name: 'Courses to not hit target', text: row.coursesDidNotReach, className: 'data-column' }
          ]
        }
      })
    }

    return []
  }, [medianHoursAndDosesToTargetChartStore.loadState])

  if (['loadError', 'updateError'].includes(medianHoursAndDosesToTargetChartStore.loadState)) {
    return (
      <MessageOverlay
        type='error'
        headerText='Failed to load analytics chart'
        messageText={<div className='panel-error-overlay-text'>{medianHoursAndDosesToTargetChartStore.error} || Something went wrong</div>}
        showBlur={false}
      >
        <AnalyticsPlotSkeleton
          loading={false}
          style={{ width: '546px', height: '266px' }}
        />
      </MessageOverlay>
    )
  }

  if (['loading', 'updating'].includes(medianHoursAndDosesToTargetChartStore.loadState)) {
    return (
      <AnalyticsPlotSkeleton
        loading={true}
        style={{ width: '546px', height: '266px' }}
      />
    )
  }

  if (medianHoursAndDosesToTargetChartStore.loadState === 'loaded' && !medianHoursAndDosesToTargetChartStore.hasPlotData()) {
    return (
      <MessageOverlay
        type='warning'
        headerText='No relevant data found'
        messageText={
          <div>
            No data found matching your query.
            <br/>
            Try adjusting the date range or any filters applied.
          </div>
        }
        showBlur={false}
      >
        <AnalyticsPlotSkeleton
          loading={false}
          style={{ width: '546px', height: '266px' }}
        />
      </MessageOverlay>
    )
  }

  return (
    <div className='doseme-analytics-chart median-hours-and-doses-to-target' key='median-hours-and-doses-to-target'>
      <div className='header-row'>
        <div className='title md-8'>Median hours and doses to target</div>
      </div>
      <div className='doseme-analytics-chart-plot'>
        <BasicList
          cols={columns}
          minRowsToShow={3}
          data={rows}
          header={true}
          textIfEmpty='No drug models selected'
          cellClassName='ife-basic-list-cell-height'
        />
      </div>
    </div>
  )
})
