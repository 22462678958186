import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsAucVsTroughOutcomesStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { AucVsTroughOutcomesPlot } from './components'
import { AnalyticsChart } from '../AnalyticsChart'

export const AucVsTroughOutcomes: React.FC<IBaseChartProps> = observer((props) => {
  const aucVsTroughOutcomesChartStore = useAnalyticsAucVsTroughOutcomesStore()

  useEffect(() => {
    aucVsTroughOutcomesChartStore.fetchAnalyticsChart()
  }, [props.hospitalIds, props.moleculeId, props.clinicianFilter, props.drugFilter, props.startDate, props.endDate])

  return (
    <AnalyticsChart
      chartStore={aucVsTroughOutcomesChartStore}
      plotComponent={AucVsTroughOutcomesPlot}
      title='AUC vs Trough outcomes'
    />
  )
})
