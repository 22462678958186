import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Accordion, FloatingTabBar } from '@doseme/cohesive-ui'
import { decode } from 'he'

import { useHospitalStore, useAuthStore } from '../../hooks/useStore'
import { TDosemeAnalyticsTabs } from './types'
import { DailyUsage } from './components/DailyUsage'
import { AucRiskThresholds } from './components/AucRiskThresholds'
import { AucVsTroughOutcomes } from './components/AucVsTroughOutcomes'
import { AKIRiskIndicators } from './components/AKIRiskIndicators'
import { DrugModelUsageBreakdown } from './components/DrugModelUsageBreakdown'
import { DrugModelUsageOverTime } from './components/DrugModelUsageOverTime'
import { UniqueUsers } from './components/UniqueUsers'
import { UserLoginNumbers } from './components/UserLoginNumbers'
import { UsageByDay } from './components/UsageByDay'
import { UsageByHour } from './components/UsageByHour'
import { ReportsByType } from './components/ReportsByType'
import { ReportsByUser } from './components/ReportsByUser'
import { DosesSimulatedByUser } from './components/DosesSimulatedByUser'
import { TroughOutcomes } from './components/TroughOutcomes'
import { DailyDoseAmounts } from './components/DailyDoseAmounts'
import { DoseMeAnalyticsFilters } from './components/DoseMeAnalyticsFilters'
import { CourseTimeToTherapeuticTarget } from './components/CourseTimeToTherapeuticTarget'
import { ChangeOverTimeToTherapeuticTarget } from './components/ChangeOverTimeToTherapeuticTarget'
import { MedianHoursAndDosesToTarget } from './components/MedianHoursAndDosesToTarget'

import './index.scss'

//FIXME - IFE-1324 - uncomment all code related to hospital filter when it is implemented
export const DoseMeAnalytics: React.FC = observer(() => {
  // FIXME - IFE-1324 - replace initial date values when a decision is made in business review
  const tempDateEnd = new Date()
  const tempDateStart: Date = new Date(new Date().setMonth(new Date().getMonth() - 3))
  const [dateRangeStart, setDateRangeStart] = useState<Date>(tempDateStart)
  const [dateRangeEnd, setDateRangeEnd] = useState<Date>(tempDateEnd)

  const [hospitalIds, setHospitalIds] = useState<string[]>([])
  const [moleculeId, setMoleculeId] = useState<string>('')
  const [clinicianFilter, setClinicianFilter] = useState<Record<string, string>>({})
  const [drugFilter, setDrugFilter] = useState<Record<string, string>>({})

  const [activeTab, setActiveTab] = useState<TDosemeAnalyticsTabs>('clinicalStatistics')

  const authStore = useAuthStore()
  const hospitalStore = useHospitalStore()

  //FIXME - IFE-1324 - update when hospital filter is implemented and business logic has been determined
  useEffect(() => {
    if (authStore.auth) {
      setHospitalIds([authStore.auth.attributes.hospitalId])
      setMoleculeId('1')
    }
  }, [])

  const displayAccordion = () => {
    if (activeTab ==='clinicalStatistics') {
      return (
        <div key='clinicalStatistics'>
          <Accordion
            type='parent'
            openOnInitial={true}
            title='Time to therapeutic target'
            childData={
              <>
                <div className='doseme-analytics-chart-panel row'>
                  <CourseTimeToTherapeuticTarget
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                  <MedianHoursAndDosesToTarget
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                </div>
                <div className='doseme-analytics-chart-panel'>
                  <ChangeOverTimeToTherapeuticTarget
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                </div>
              </>
            }
          />
          <Accordion
            type='parent'
            title='AKI risk indicators'
            childData={
              <>
                <div className='doseme-analytics-chart-panel aki-risk-indicators'>
                  <AKIRiskIndicators
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                </div>
              </>
            }
          />
          <Accordion
            type='parent'
            title='Patient risk thresholds'
            childData={
              <>
                <div className='doseme-analytics-chart-panel'>
                  <AucRiskThresholds
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                </div>

                <div className='doseme-analytics-chart-panel'>
                  <TroughOutcomes
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                </div>
              </>
            }
          />
          <Accordion
            type='parent'
            title='Daily dose amounts'
            childData={
              <>
                <div className='doseme-analytics-chart-panel'>
                  <DailyDoseAmounts
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                </div>
              </>
            }
          />
          <Accordion
            type='parent'
            title='AUC vs Trough outcomes'
            childData={
              <>
                <div className='doseme-analytics-chart-panel'>
                  <AucVsTroughOutcomes
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                </div>
              </>
            }
          />
        </div>
      )
    }

    if (activeTab ==='drugModelUsage') {
      return (
        <div key='drugModelUsage'>
          <Accordion
            type='parent'
            title='Drug model usage breakdown'
            childData={
              <>
                <div className='doseme-analytics-chart-panel row'>
                  <DrugModelUsageBreakdown
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                  <DrugModelUsageOverTime
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                </div>
              </>
            }
            openOnInitial={true}
          />
          <Accordion
            type='parent'
            title='Dose simulations and dosing reports'
            childData={
              <>
                <div className='doseme-analytics-chart-panel'>
                  <ReportsByType
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                </div>
                <div className='doseme-analytics-chart-panel'>
                  <ReportsByUser
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                </div>
                <div className='doseme-analytics-chart-panel'>
                  <DosesSimulatedByUser
                    drugFilter={drugFilter}
                    hospitalIds={hospitalIds}
                    moleculeId={moleculeId}
                    clinicianFilter={clinicianFilter}
                    startDate={dateRangeStart}
                    endDate={dateRangeEnd}
                  />
                </div>
              </>
            }
          />
        </div>
      )
    }

    return (
      <div key='platformusage'>
        <Accordion
          type='parent'
          title='User logins'
          childData={
            <>
              <div className='doseme-analytics-chart-panel'>
                <UniqueUsers
                  drugFilter={drugFilter}
                  hospitalIds={hospitalIds}
                  moleculeId={moleculeId}
                  clinicianFilter={clinicianFilter}
                  startDate={dateRangeStart}
                  endDate={dateRangeEnd}
                />
              </div>
              <div className='doseme-analytics-chart-panel'>
                <UserLoginNumbers
                  drugFilter={drugFilter}
                  hospitalIds={hospitalIds}
                  moleculeId={moleculeId}
                  clinicianFilter={clinicianFilter}
                  startDate={dateRangeStart}
                  endDate={dateRangeEnd}
                />
              </div>
            </>
          }
          openOnInitial={true}
        />
        <Accordion
          type='parent'
          title='Usage trends'
          childData={
            <>
              <div className='doseme-analytics-chart-panel'>
                <DailyUsage
                  drugFilter={drugFilter}
                  hospitalIds={hospitalIds}
                  moleculeId={moleculeId}
                  clinicianFilter={clinicianFilter}
                  startDate={dateRangeStart}
                  endDate={dateRangeEnd}
                />

                <UsageByDay
                  drugFilter={drugFilter}
                  hospitalIds={hospitalIds}
                  moleculeId={moleculeId}
                  clinicianFilter={clinicianFilter}
                  startDate={dateRangeStart}
                  endDate={dateRangeEnd}
                />
              </div>

              <div className='doseme-analytics-chart-panel'>
                <UsageByHour
                  drugFilter={drugFilter}
                  hospitalIds={hospitalIds}
                  moleculeId={moleculeId}
                  clinicianFilter={clinicianFilter}
                  startDate={dateRangeStart}
                  endDate={dateRangeEnd}
                />
              </div>
            </>
          }
        />
      </div>
    )
  }

  return (
    <div className='doseme-analytics-content'>
      <div className='analytics-header ml-2'>
        <span className='analytics-title'>
          DoseMe Analytics for&nbsp;
          <span className='analytics-hospital-name'>{decode(hospitalStore.hospital?.attributes.name || '')}</span>
        </span>

      </div>
      <DoseMeAnalyticsFilters
        hospitalIds={hospitalIds}
        moleculeId={moleculeId}
        tab={activeTab}
        dateRangeStart={dateRangeStart}
        dateRangeEnd={dateRangeEnd}
        drugFilter={drugFilter}
        clinicianFilter={clinicianFilter}
        setDateRangeStart={setDateRangeStart}
        setDateRangeEnd={setDateRangeEnd}
        setClinicianFilter={setClinicianFilter}
        setDrugFilter={setDrugFilter}
      />
      <div className='doseme-analytics-panel'>
        <FloatingTabBar
          tabNames={[
            { id: 'clinicalStatistics', displayName: 'Clinical statistics' },
            { id: 'drugModelUsage', displayName: 'Drug model usage' },
            { id: 'platformUsage', displayName: 'Platform usage' }
          ]}
          onSelectTab={(tab: string) => setActiveTab(tab as TDosemeAnalyticsTabs)}
          activeTab={activeTab}
          disabled={hospitalStore.loadState !== 'loaded'}
        />
        <div className='analytics-accordion-outer'>
          {displayAccordion()}
        </div>
      </div>
    </div>
  )
})
