import { IAnalyticsChartResponse } from '../../../../store/DoseMeAnalytics/analyticsChart/types'

export const aucVsTroughOutcomesMockData: IAnalyticsChartResponse = {
  data: {
    type: '',
    attributes: {
      name: 'AUC vs Trough outcomes',
      plotData: {
        plotSeries: {
          oneCompAdult: { label: '1-Comp (Adult)', type: 'scatter', color: '#8cc1cb' },
          twoCompAdult: { label: '2-Comp (Adult)', type: 'scatter', color: '#116985' },
          obeseEnhancedAdult: { label: 'Obese: Enhanced (Adult)', type: 'scatter', color: '#f28e2a' }
        },
        dataPoints: {
          oneCompAdult: [
            {
              auc24: 145,
              trough: 1,
              tooltip: {
                title: '1-Comp (Adult)',
                auc24: {
                  prefix: 'AUC24',
                  suffix: 'mg.h/L',
                  value: '145'
                },
                trough: {
                  prefix: 'Trough',
                  suffix: 'mg/L',
                  value: '1'
                }
              }
            },
            {
              auc24: 180,
              trough: 5,
              tooltip: {
                title: '1-Comp (Adult)',
                auc24: {
                  prefix: 'AUC24',
                  suffix: 'mg.h/L',
                  value: '180'
                },
                trough: {
                  prefix: 'Trough',
                  suffix: 'mg/L',
                  value: '5'
                }
              }
            },
            {
              auc24: 625,
              trough: 14,
              tooltip: {
                title: '1-Comp (Adult)',
                auc24: {
                  prefix: 'AUC24',
                  suffix: 'mg.h/L',
                  value: '625'
                },
                trough: {
                  prefix: 'Trough',
                  suffix: 'mg/L',
                  value: '14'
                }
              }
            },
            {
              auc24: 160,
              trough: 3,
              tooltip: {
                title: '1-Comp (Adult)',
                auc24: {
                  prefix: 'AUC24',
                  suffix: 'mg.h/L',
                  value: '160'
                },
                trough: {
                  prefix: 'Trough',
                  suffix: 'mg/L',
                  value: '3'
                }
              }
            }
          ],
          twoCompAdult: [
            {
              auc24: 245,
              trough: 2,
              tooltip: {
                title: '2-Comp (Adult)',
                auc24: {
                  prefix: 'AUC24',
                  suffix: 'mg.h/L',
                  value: '245'
                },
                trough: {
                  prefix: 'Trough',
                  suffix: 'mg/L',
                  value: '2'
                }
              }
            },
            {
              auc24: 194,
              trough: 6,
              tooltip: {
                title: '2-Comp (Adult)',
                auc24: {
                  prefix: 'AUC24',
                  suffix: 'mg.h/L',
                  value: '194'
                },
                trough: {
                  prefix: 'Trough',
                  suffix: 'mg/L',
                  value: '6'
                }
              }
            },
            {
              auc24: 635,
              trough: 24,
              tooltip: {
                title: '2-Comp (Adult)',
                auc24: {
                  prefix: 'AUC24',
                  suffix: 'mg.h/L',
                  value: '635'
                },
                trough: {
                  prefix: 'Trough',
                  suffix: 'mg/L',
                  value: '24'
                }
              }
            },
            {
              auc24: 435,
              trough: 18,
              tooltip: {
                title: '2-Comp (Adult)',
                auc24: {
                  prefix: 'AUC24',
                  suffix: 'mg.h/L',
                  value: '435'
                },
                trough: {
                  prefix: 'Trough',
                  suffix: 'mg/L',
                  value: '18'
                }
              }
            }
          ],
          obeseEnhancedAdult: [
            {
              auc24: 345,
              trough: 3,
              tooltip: {
                title: 'Obese: Enhanced (Adult)',
                auc24: {
                  prefix: 'AUC24',
                  suffix: 'mg.h/L',
                  value: '345'
                },
                trough: {
                  prefix: 'Trough',
                  suffix: 'mg/L',
                  value: '3'
                }
              }
            },
            {
              auc24: 110,
              trough: 4,
              tooltip: {
                title: 'Obese: Enhanced (Adult)',
                auc24: {
                  prefix: 'AUC24',
                  suffix: 'mg.h/L',
                  value: '110'
                },
                trough: {
                  prefix: 'Trough',
                  suffix: 'mg/L',
                  value: '4'
                }
              }
            },
            {
              auc24: 798,
              trough: 29,
              tooltip: {
                title: 'Obese: Enhanced (Adult)',
                auc24: {
                  prefix: 'AUC24',
                  suffix: 'mg.h/L',
                  value: '798'
                },
                trough: {
                  prefix: 'Trough',
                  suffix: 'mg/L',
                  value: '29'
                }
              }
            },
            {
              auc24: 355,
              trough: 16,
              tooltip: {
                title: 'Obese: Enhanced (Adult)',
                auc24: {
                  prefix: 'AUC24',
                  suffix: 'mg.h/L',
                  value: '355'
                },
                trough: {
                  prefix: 'Trough',
                  suffix: 'mg/L',
                  value: '16'
                }
              }
            }
          ]
        }
      }
    }
  },
  metadata: {
    filters: [
      { name: 'hospitals', value: ['1234'] },
      { name: 'startDate', value: '2024-07-01' },
      { name: 'endDate', value: '2024-09-30' }
    ]
  }
}
