import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsChartAucRiskThresholdsStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { AucRiskThresholdsPlot } from './components'
import { AnalyticsChart } from '../AnalyticsChart'

export const AucRiskThresholds: React.FC<IBaseChartProps> = observer((props) => {
  const aucRiskThresholdsChartStore = useAnalyticsChartAucRiskThresholdsStore()

  useEffect(() => {
    aucRiskThresholdsChartStore.fetchAnalyticsChart()
  }, [props.hospitalIds, props.moleculeId, props.clinicianFilter, props.drugFilter, props.startDate, props.endDate])

  return (
    <AnalyticsChart
      chartStore={aucRiskThresholdsChartStore}
      plotComponent={AucRiskThresholdsPlot}
      title='AUC24 Outcomes'
    />
  )
})
