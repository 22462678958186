import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Skeleton } from '@doseme/cohesive-ui'

import { useAuthStore } from '../../hooks/useStore'

import './index.scss'

export const minSupportedWidth = 820

export const UnsupportedDeviceWidth: React.FC = observer(() => {
  const authStore = useAuthStore()

  useEffect(() => {
    const logOut = async () => {
      // This will also flush all stores in rootStore
      await authStore.authLogout()
    }

    logOut()

    return () => {}
  }, [])

  return (
    <div className='unsupported-device'>
      <Skeleton.UnsupportedWidth />
    </div>
  )
})
