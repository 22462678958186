import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsChartDailyDoseAmountsStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { DailyDoseAmountsPlot } from './components'
import { AnalyticsChart } from '../AnalyticsChart'

export const DailyDoseAmounts: React.FC<IBaseChartProps> = observer((props) => {
  const analyticsChartDailyDoseAmountsStore = useAnalyticsChartDailyDoseAmountsStore()

  useEffect(() => {
    analyticsChartDailyDoseAmountsStore.fetchAnalyticsChart()
  }, [props.hospitalIds, props.moleculeId, props.clinicianFilter, props.drugFilter, props.startDate, props.endDate])

  return (
    <AnalyticsChart
      chartStore={analyticsChartDailyDoseAmountsStore}
      plotComponent={DailyDoseAmountsPlot}
      title='Avg. Daily Dose Amounts'
    />
  )
})
