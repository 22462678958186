import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsChartAKIRiskFactorsStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { AKIRiskIndicatorsPlot } from './components'
import { AnalyticsChart } from '../AnalyticsChart'

export const AKIRiskIndicators: React.FC<IBaseChartProps> = observer((props) => {
  const analyticsChartAKIRiskFactorsStore = useAnalyticsChartAKIRiskFactorsStore()

  useEffect(() => {
    analyticsChartAKIRiskFactorsStore.fetchAnalyticsChart()
  }, [props.hospitalIds, props.moleculeId, props.clinicianFilter, props.drugFilter, props.startDate, props.endDate])

  return (
    <AnalyticsChart
      chartStore={analyticsChartAKIRiskFactorsStore}
      plotComponent={AKIRiskIndicatorsPlot}
      infoBubbleContent={
        <div className='doseme-analytics-chart-bubble'>
          Courses using adult hemodialysis, pediatric and neonate models have been excluded from AKI risk data.
        </div>
      }
      title='AKI risk indicators'
    />
  )
})
