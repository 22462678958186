import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsChartUsageByHourStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { UsageByHourPlot } from './components'
import { AnalyticsChart } from '../AnalyticsChart'

export const UsageByHour: React.FC<IBaseChartProps> = observer((props) => {
  const usageByHourChartStore = useAnalyticsChartUsageByHourStore()

  useEffect(() => {
    usageByHourChartStore.fetchAnalyticsChart(
      props.startDate,
      props.endDate,
      props.hospitalIds,
      props.moleculeId,
      props.clinicianFilter
    )
  }, [props.hospitalIds, props.moleculeId, props.clinicianFilter, props.startDate, props.endDate])

  return (
    <AnalyticsChart
      chartStore={usageByHourChartStore}
      plotComponent={UsageByHourPlot}
      title='Usage by hour of the day'
    />
  )
})
