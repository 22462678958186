import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ActionButton, Icons, ITimeState, ListButton, MessageOverlay } from '@doseme/cohesive-ui'
import { format, parseISO } from 'date-fns'
import classnames from 'classnames'
import moment from 'moment'

import { IFormState } from '../../../../../../../../types/validation'
import { buildDocetaxelCustomDoseAttributes } from './constants'
import { dateFnsRawDateOnly, rawTimeOnly } from '../../../../../../../../constants/timeFormat'
import { NextDoseAtDateTime } from '../SimulationPanel/components/NextDoseAtDateTime'
import {
  useAdministrationsStore,
  useCourseFeaturesStore,
  useCourseStore,
  useDosingRecommendationStore,
  useHistoricalSimulationStore,
  useObservationsStore
} from '../../../../../../../../hooks/useStore'
import { getLastAdministration, isValidTimeInput } from '../SimulationPanel/utils'
import { SimulationForm } from './components/SimulationForm'
import { calculateDefaultNextDoseTime, calculateMinNextDoseTime, calculateMaxNextDoseTime } from '../SimulationPanel/components/NextDoseAtDateTime/utils/dateProps'
import { sortAdministrationsByDateAsc } from '../../../../../../../../utils/sorting'
import { SimulatedGCSFForm } from './components/SimulatedGCSFModal'
import { IModelDoseRecommendation, IPOSTDrugSpecificAttr } from '../../../../../../../../store/dosingRecommendation/types'
import { getGCSFAdminLimits } from './components/SimulatedGCSFModal/utils'
import { formatErrorMessage } from '../../../../../../../../utils/errors'

import './index.scss'

interface IProps {
  hospitalTimezone: string
  patientId: string
  courseId: string
  drugModelId: string
  setCurrentSimulation: (value: React.SetStateAction<IModelDoseRecommendation | null>) => void
}

export const DocetaxelSimulationPanel: React.FC<IProps> = observer((props) => {
  const [nextDoseAt, setNextDoseAt] = useState<Date>(new Date())
  const [nextDoseAtDate, setNextDoseAtDate] = useState<Date>(parseISO(format(nextDoseAt, dateFnsRawDateOnly)))
  const [nextDoseAtTime, setNextDoseAtTime] = useState<ITimeState>({
    hh: nextDoseAt.getHours().toString().padStart(2, '0'),
    mm: nextDoseAt.getMinutes().toString().padStart(2, '0')
  })
  const [minDate, setMinDate] = useState<Date | null>(new Date())
  const [maxDate, setMaxDate] = useState<Date | null>(new Date())
  const [errorDismissed, setErrorDismissed] = useState(false)
  const [calculated, setCalculated] = useState(false)
  const [showSimulatedGCSFModal, setShowSimulatedGCSFModal] = useState(false)
  const [cachedSimulatedFormData, setCachedSimulatedFormData] = useState<IFormState | null>(null)
  const [cachedGCFSModalData, setCachedGCFSModalData] = useState<IFormState | null>(null)

  const courseStore = useCourseStore()
  const historicalSimulationStore = useHistoricalSimulationStore()
  const dosingRecommendationStore = useDosingRecommendationStore()
  const administrationsStore = useAdministrationsStore()
  const observationsStore = useObservationsStore()
  const courseFeaturesStore = useCourseFeaturesStore()
  const historicalSimulationWarnings = historicalSimulationStore.historicalSimulationData?.attributes.plotData?.warnings
  const simulationLimits = historicalSimulationStore.historicalSimulationData?.attributes.limitsOverridesByDosingType?.customDose?.simulation

  useEffect(() => {
    const isTimeValid = isValidTimeInput(nextDoseAtTime)

    if (isTimeValid) {
      setNextDoseAt(
        parseISO(format(nextDoseAtDate, dateFnsRawDateOnly)! + 'T' + nextDoseAtTime.hh + ':' + nextDoseAtTime.mm)
      )
      setCalculated(false)
    }
  }, [nextDoseAtDate, nextDoseAtTime])

  // This useEffect has a number of triggers (see dependency array).
  // It invalidates the last calculation when any of the dependencies are changed.
  useEffect(() => {
    if (!calculated) {
      dosingRecommendationStore.resetPredictedData()
      dosingRecommendationStore.setCustomDoseCalculationValid(false)
    }
  }, [
    calculated,
    administrationsStore.loadState,
    observationsStore.loadState,
    courseFeaturesStore.loadState,
    courseStore.loadState
  ])

  useEffect(() => {
    // Sets the errorDismissed flag to false everytime a new dose recommendation is calculated
    if (['loadError', 'updateError', 'loaded'].includes(dosingRecommendationStore.loadState) && errorDismissed) {
      setErrorDismissed(false)
    }

    // Sets the calculated flag to true everytime a new dose recommendation is calculated sucessfully
    if (dosingRecommendationStore.loadState === 'loaded') {
      setCalculated(true)

      const modelResults =
        dosingRecommendationStore.dosingRecommendation.customDose?.attributes.modelResults
      props.setCurrentSimulation(modelResults?.customDose?.doseRecommendation || null)
    }
  }, [dosingRecommendationStore.loadState])

  // This useEffect updates the min and max next dose times when the historical data changes.
  // It will also reset the date and time pickers to the new default next dose time
  useEffect(() => {
    if (historicalSimulationStore.loadState === 'loaded') {
      //Clear the dosingRecommendationStore when the historicalSimulationStore is reloaded
      dosingRecommendationStore.resetStore()
      setCalculated(false)

      const administrations = [...administrationsStore.administrations.values()].filter(
        (admin) =>
          !admin.attributes.excludeFromCalculations && admin.attributes.administrationType.isPrimary
      )
      const sortedIncludedPrimaryAdministrations = sortAdministrationsByDateAsc(administrations)
      const maximumDosingIntervalHours = courseStore.course!.attributes.limits.dosingPeriod.max.value
      const defaultIntervalHours = courseStore.course!.attributes.limits.dosingPeriod.default.value

      const lastAdministration = getLastAdministration(sortedIncludedPrimaryAdministrations)

      // default, max and min are all naive Date objects assumed to be in hospital timezone
      const dosingInterval = historicalSimulationStore.historicalSimulationData?.attributes.assumedDosingInterval || defaultIntervalHours

      const defaultNextDoseTime = calculateDefaultNextDoseTime(
        props.hospitalTimezone,
        dosingInterval,
        lastAdministration?.attributes.administeredAt.value
      )

      const minNextDoseTime = calculateMinNextDoseTime(props.hospitalTimezone, lastAdministration)

      const maxNextDoseTime = calculateMaxNextDoseTime(
        props.hospitalTimezone,
        maximumDosingIntervalHours,
        lastAdministration
      )

      setNextDoseAt(defaultNextDoseTime)
      setNextDoseAtDate(parseISO(format(defaultNextDoseTime, dateFnsRawDateOnly)))
      setNextDoseAtTime({
        hh: defaultNextDoseTime.getHours().toString().padStart(2, '0'),
        mm: defaultNextDoseTime.getMinutes().toString().padStart(2, '0')
      })
      setMinDate(minNextDoseTime)
      setMaxDate(maxNextDoseTime)
    }
  }, [historicalSimulationStore.loadState])

  const handleCustomDoseCalculation = (simulationForm: IFormState | null, GCSFModalForm: IFormState | null, isSimulatedForm: boolean): void => {
    const drugSpecificAttr: IPOSTDrugSpecificAttr = {
      plannedCoAdministrations: []
    }

    // this function can receive form data from two different locations
    // save the data for use when the function is called from the other location
    if (isSimulatedForm) {
      setCachedSimulatedFormData(simulationForm)
    } else {
      setCachedGCFSModalData(GCSFModalForm)
    }

    if (GCSFModalForm) {
      const adminLimts = getGCSFAdminLimits(
        GCSFModalForm.values.drug,
        courseStore.course?.attributes.administrationTypes
      )

      if (adminLimts) {
        drugSpecificAttr.plannedCoAdministrations = [
          {
            administrationTypeId: GCSFModalForm.values.drug,
            numberOfDoses: GCSFModalForm.values.numberOfDoses,
            nextDoseDay: parseInt(GCSFModalForm.values.nextDoseDay),
            doseAmount: {
              value: GCSFModalForm.values.amount,
              unit: adminLimts?.doseClinical.default.unit
            },
            dosingInterval: {
              value: adminLimts?.dosingInterval.default.value,
              unit: adminLimts?.dosingInterval.default.unit
            }
          }
        ]
      }
    }

    if (simulationForm) {
      dosingRecommendationStore.fetchPredictedDosingRecommendation(
        props.patientId,
        props.courseId,
        props.drugModelId,
        'CustomDoseSimulation',
        buildDocetaxelCustomDoseAttributes(
          simulationForm,
          nextDoseAt,
          props.hospitalTimezone,
          simulationLimits
        ),
        ['customDose'],
        moment
          .tz(format(nextDoseAt, dateFnsRawDateOnly) + 'T' + format(nextDoseAt, rawTimeOnly), props.hospitalTimezone)
          .utc()
          .toISOString(),
        drugSpecificAttr
      )
    }
  }

  const simulatedGCSFBar: JSX.Element = (
    <div className={classnames('simulated-gcsf-bar')}>
      <span>
        <b>Simulated GCSFs: </b>
        {
          dosingRecommendationStore.dosingRecommendation.customDose?.attributes.modelResults?.customDose
            ?.drugSpecificResults?.gcsfScheduleString || '-'
        }
      </span>
      <ActionButton
        actionType='edit'
        size='md'
        onClick={() => setShowSimulatedGCSFModal(true)}
      />
    </div>
  )

  const simulatedNextCycleBar: JSX.Element = (
    <div className={classnames('next-cycle-bar')}>
      <span>
        <b>Next Cycle: </b>
        {
          dosingRecommendationStore.dosingRecommendation.customDose?.attributes
            .modelResults?.customDose?.doseRecommendation?.doseDescription || '-'
        }
      </span>
    </div>
  )

  const overlaidContent = (children: JSX.Element) => {
    if (!errorDismissed && ['loaded', 'loadError', 'updateError'].includes(dosingRecommendationStore.loadState)) {
      if (dosingRecommendationStore.error.customDose) {
        return (
          <MessageOverlay
            type='error'
            headerText='Failed to load dosing recommendation'
            messageText={
              <div className='panel-error-overlay-text'>
                {formatErrorMessage(dosingRecommendationStore.error.customDose)}
                <div className='overlay-message-button'>
                  <ListButton size='md' onClick={() => setErrorDismissed(true)}>
                    Dismiss
                  </ListButton>
                </div>
              </div>
            }
          >
            {children}
          </MessageOverlay>
        )
      }

      const message =
        dosingRecommendationStore.dosingRecommendation.customDose?.attributes.modelResults?.message?.customDose

      if (message) {
        return (
          <MessageOverlay
            type='info'
            headerText={'Customized dose is unavailable'}
            messageText={
              <div className='panel-error-overlay-text'>
                {formatErrorMessage(message)}
                <div className='overlay-message-button'>
                  <ListButton size='md' onClick={() => setErrorDismissed(true)}>
                    Dismiss
                  </ListButton>
                </div>
              </div>
            }
          >
            {children}
          </MessageOverlay>
        )
      }
    }

    return children
  }

  const warningContent = (warnings: string[]) => {
    const warningItems = warnings.map((warning) => {
      return (
        <div key={warning} className='docetaxel-simulation-warning'>
          <Icons.Alert />
          {warning}
        </div>
      )
    })

    return (
      <div>
        <div className='docetaxel-simulation-warning-title'>
          More data required
          <Icons.Alert />
        </div>
        <div className='docetaxel-simulation-warning-subtitle'>
          Some requirements for individualized dose simulation have not been met:
        </div>
        {warningItems}
      </div>
    )
  }

  const loading = ['loading', 'updating'].includes(dosingRecommendationStore.loadState)
    || ['loading', 'updating'].includes(courseStore.loadState)
    || ['loading', 'updating'].includes(administrationsStore.loadState)
    || ['loading', 'updating'].includes(observationsStore.loadState)
    || ['loading', 'updating'].includes(historicalSimulationStore.loadState)
    || ['loading', 'updating'].includes(courseFeaturesStore.loadState)

  const disabled = loading || historicalSimulationStore.loadState !== 'loaded'
    || !!courseStore.course?.attributes.courseArchived
    || !!courseStore.course?.attributes.isReadOnly
    || (historicalSimulationWarnings && historicalSimulationWarnings.length > 0)

  return (
    <>
      <SimulatedGCSFForm
        show={showSimulatedGCSFModal}
        setShow={setShowSimulatedGCSFModal}
        calculateCustomDose={(form: IFormState | null) => handleCustomDoseCalculation(cachedSimulatedFormData, form, false)}
        GCSFData={
          dosingRecommendationStore.dosingRecommendation.customDose?.attributes.modelResults?.customDose
            ?.drugSpecificResults?.gcsfScheduleParts
        }
        adminTypes={courseStore.course?.attributes.administrationTypes.filter((drug) => drug.shortName === 'GCSF')}
      />
      <div className='simulation-panel content-panel-with-shadow mb-4'>
        <div className='docetaxel-simulation-panel-content'>
          <div className='d-flex align-items-center w-100 mb-3'>
            <div
              className={classnames('docetaxel-simulation-title mr-3', {
                'docetaxel-simulation-title-disabled': disabled
              })}
            >
              Next cycle starting at
            </div>
            <div className='datetimeinput-line-buffer'>
              <NextDoseAtDateTime
                disabled={disabled}
                hospitalTimezone={props.hospitalTimezone}
                nextDoseAtDate={nextDoseAtDate}
                nextDoseAtTime={nextDoseAtTime}
                minDate={minDate}
                maxDate={maxDate}
                onUpdateDate={setNextDoseAtDate}
                onUpdateTime={setNextDoseAtTime}
              />
            </div>
          </div>
          {overlaidContent(
            historicalSimulationWarnings && historicalSimulationWarnings.length
              ? warningContent(historicalSimulationWarnings)
              : <SimulationForm
                loading={loading}
                limits={simulationLimits}
                disabled={disabled}
                calculated={calculated}
                setCalculated={(calculated: boolean) => setCalculated(calculated)}
                calculateCustomDose={(form: IFormState) => handleCustomDoseCalculation(form, cachedGCFSModalData, true)}
              />
          )}
        </div>
        {!disabled && calculated && simulatedGCSFBar}
        {!disabled && calculated && simulatedNextCycleBar}
      </div>
    </>
  )
})
