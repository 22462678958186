import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsChartUniqueUsersStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { UniqueUsersPlot } from './components'
import { AnalyticsChart } from '../AnalyticsChart'

export const UniqueUsers: React.FC<IBaseChartProps> = observer((props) => {
  const uniqueUsersChartStore = useAnalyticsChartUniqueUsersStore()

  useEffect(() => {
    uniqueUsersChartStore.fetchAnalyticsChart(
      props.startDate,
      props.endDate,
      props.hospitalIds,
      props.moleculeId,
      props.clinicianFilter
    )
  }, [props.hospitalIds, props.moleculeId, props.clinicianFilter, props.startDate, props.endDate])

  return (
    <AnalyticsChart
      chartStore={uniqueUsersChartStore}
      plotComponent={UniqueUsersPlot}
      title='Unique users per day'
      plotOverlayHeight='240px'
    />
  )
})
