import { action, makeObservable, observable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../../utils/axiosClient'
import { AnalyticsRootStore } from '../AnalyticsRootStore/AnalyticsRootStore'
import { TLoadState } from '../../types'
import { AnalyticsChart } from './AnalyticsChart'
import { IAnalyticsChartResponse } from './types'

export abstract class AnalyticsChartStore {
  rootStore: AnalyticsRootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  analyticsChartData: AnalyticsChart['data'] | null = null
  analyticsChartMetadata: AnalyticsChart['metadata'] | null = null

  url: string = ''

  constructor(rootStore: AnalyticsRootStore, url?: string) {
    makeObservable(this, {
      analyticsChartData: observable,
      loadState: observable,
      error: observable,
      setAnalyticsChart: action,
      setLoadState: action,
      setError: action,
      resetStore: action,
      fetchAnalyticsChart: action
    })

    this.url = url || ''
    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetStore() {
    this.analyticsChartData = null
    this.analyticsChartMetadata = null
    this.error = ''

    this.setLoadState('initial')
  }

  setAnalyticsChart(analyticsChartAttrs: IAnalyticsChartResponse) {
    const analyticsChart = new AnalyticsChart(this, analyticsChartAttrs)
    this.analyticsChartData = analyticsChart.data
    this.analyticsChartMetadata = analyticsChart.metadata
  }

  hasPlotData() {
    return (this.loadState === 'loaded'
      && this.analyticsChartData
      && this.analyticsChartData.attributes.plotData.dataPoints.length > 0)
  }

  async fetchAnalyticsChart(
    startDate: Date,
    endDate: Date,
    hospitalIds?: string[],
    moleculeId?: string,
    clinicianFilter?: Record<string, string>,
    drugFilter?: Record<string, string>
  ) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const params = new URLSearchParams()
    params.append('startDate', startDate.toISOString().slice(0, 10))
    params.append('endDate', endDate.toISOString().slice(0, 10))

    if (hospitalIds && hospitalIds.length > 0) {
      params.append('hospitals', hospitalIds.join(','))
    }

    if (moleculeId) {
      params.append('molecule', moleculeId)
    }

    if (clinicianFilter) {
      const clinicians: string[] = []
      Object.keys(clinicianFilter).forEach((clinicianFilter) => {
        clinicians.push(clinicianFilter)
      })
      if (clinicians.length > 0) {
        params.append('clinicians', clinicians.join(','))
      }
    }

    if (drugFilter) {
      const drugs: string[] = []
      Object.keys(drugFilter).forEach((drug) => {
        drugs.push(drug)
      })
      if (drugs.length > 0) {
        params.append('drugs', drugs.join(','))
      }
    }

    await axiosClient
      .get<AxiosResponse<IAnalyticsChartResponse>>(this.url, { headers, params })
      .then((response: AxiosResponse) => {
        this.setAnalyticsChart(response.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.analyticsErrorStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
