import { makeObservable, override } from 'mobx'

import { analyticsChartsUsageByHourGetUrl } from '../../../../constants/api'
import { AnalyticsRootStore } from '../../AnalyticsRootStore/AnalyticsRootStore'
import { AnalyticsChartStore } from '../AnalyticsChartsStore'

export class AnalyticsChartUsageByHourStore extends AnalyticsChartStore {
  constructor(rootStore: AnalyticsRootStore) {
    super(rootStore, analyticsChartsUsageByHourGetUrl())
    makeObservable(this, {
      fetchAnalyticsChart: override
    })
  }
}
