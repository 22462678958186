import { InfoModal, Modal, DosingMatrix, IDosingMatrixValues, Icons, ACTIVE_GOLD } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useState, useEffect, SetStateAction } from 'react'

import { useCourseStore, useDosingMatrixStore, useDosingRecommendationStore } from '../../../../../../../../../../hooks/useStore'
import { IDosingMatrix } from '../../../../../../../../../../store/dosingMatrix/types'
import { IModalProps } from '../../../../../../../../types'
import { getMatrixKeys, sortDosingMatrixData } from './utils'

import './index.scss'

interface IProps extends IModalProps {
  modelPrettyName: string
  setDosingMatrixCustomSimulationValue: (value: SetStateAction<IDosingMatrixValues | null>) => void
}

export const DosingMatrixModal: React.FC<IProps> = observer((props) => {
  const [dosingMatrix, setDosingMatrix] = useState<IDosingMatrix | null>(null)

  const dosingMatrixStore = useDosingMatrixStore()
  const courseStore = useCourseStore()
  const dosingRecommendationStore = useDosingRecommendationStore()

  useEffect(() => {
    if (dosingMatrixStore.loadState === 'loaded' && dosingMatrixStore.dosingMatrix) {
      setDosingMatrix(dosingMatrixStore.dosingMatrix)
    }
  }, [dosingMatrixStore.loadState])

  const simulationTypeSubHeading = () => {
    if (props.modelPrettyName === 'Population') {
      return (
        <div className='dosing-matrix--modal-subheading-type'>
          <div className='dosing-matrix--modal-subheading-population'>Population</div>
          &nbsp;based dose simulation
        </div>
      )
    }

    return (
      <div className='dosing-matrix--modal-subheading-type'>
        <div className='dosing-matrix--modal-subheading-individualized'>Individualized</div>
        &nbsp;dose simulation
      </div>
    )
  }

  const matrixModalSubtitle = (): JSX.Element => {
    if (dosingMatrixStore.loadState === 'loading') {
      return (
        <>
          {simulationTypeSubHeading()}
          <div className='dosing-matrix-modal-subheading'>
            Based on a default target of{' '}
            <span style={{ marginTop: '-15px' }}>
              <Icons.ThinSpinner strokeWidth={7} stroke={ACTIVE_GOLD} r={15} width='50px' />
            </span>
          </div>
        </>
      )
    }

    if (['loaded', 'loadError', 'updateError'].includes(dosingMatrixStore.loadState) && !dosingMatrix) {
      return (
        <>
          {simulationTypeSubHeading()}
          <div className='dosing-matrix-modal-subheading'>
            Based on a default target of
            <span style={{ verticalAlign: 'middle', marginLeft: '8px' }}>
              <Icons.Error />
            </span>
          </div>
        </>
      )
    }

    const matrixKeys = getMatrixKeys(dosingMatrix)
    if (dosingMatrix && matrixKeys) {
      return (
        <>
          {simulationTypeSubHeading()}
          <div className='dosing-matrix-modal-subheading'>
            Based on a default target of
            <span className='font-weight-bold ml-1'>
              AUC24 {dosingMatrix?.attributes.clinicalTargets.default.auc24.value}{' '}
              {dosingMatrix?.attributes.clinicalTargets.default.auc24.unit?.name} over{' '}
              {
                dosingRecommendationStore.dosingRecommendation.indPop?.attributes.modelResults?.indPop
                  ?.doseRecommendation?.doseRecommendationAttributes?.daysOfDosing?.value
              }{' '}
              days
            </span>
          </div>
        </>
      )
    }

    return <></>
  }

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <InfoModal
        title='Preview of alternative dosing regimens'
        subtitle={matrixModalSubtitle()}
        size='variable'
        message={
          <DosingMatrix
            type={props.modelPrettyName}
            rowKeys={dosingMatrix ? getMatrixKeys(dosingMatrix) : undefined}
            matrixData={dosingMatrix ? sortDosingMatrixData(dosingMatrix) : null}
            isLoading={['initial', 'loading', 'updating'].includes(dosingMatrixStore.loadState)}
            hospitalLimits={{
              min: courseStore.course?.attributes.limits.aucTarget.min.value!,
              max: courseStore.course?.attributes.limits.aucTarget.max.value!
            }}
            setDosingMatrixCustomSimulationValue={props.setDosingMatrixCustomSimulationValue}
          />
        }
        onDismiss={() => props.setShow(false)}
      />
    </Modal>
  )
})
