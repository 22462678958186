import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Keycloak from 'keycloak-js'
import { v4 as uuidv4 } from 'uuid'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { Icons, InfoBubble, LOADING_GREY } from '@doseme/cohesive-ui'

import { TOSFooter } from '../TOSFooter'
import { useAuthStore, useClinicianStore } from '../../../../hooks/useStore'
import { appInsights } from '../../../AzureAppInsights'
import { loginRedirect } from '../LoginPage/utils'

import './index.scss'

export const CheckSSOPage: React.FC = observer(() => {
  const authStore = useAuthStore()
  const clinicianStore = useClinicianStore()

  const navigate = useNavigate()

  const [displaySSOError, setDisplaySSOError] = useState<boolean>(false)

  useEffect(() => {
    if (authStore.isAuthenticated()) {
      loginRedirect(authStore.auth, clinicianStore).then((redirectURL) =>
        navigate(redirectURL)
      )
    }

    // This block is triggered from the redirection _back_ from any SSO authentication flow using Keycloak.
    // Keycloak (if auth flow completes) will issue a 304 back to our /check-sso route with # query string values
    // (session_state, state, code)
    //
    // We then confirm keycloak has authenticated our user (in keycloak session management), and pass the access token
    // to RXBE to in turn verify and convert to a user authentication response (if verified).
    //
    // Lastly, we end the keycloak session, so we only need to track _our_ doseme_session for auth/session timeout.
    const hash = window.location.hash
    if (hash) {
      const hashParams = new URLSearchParams(hash.substring(1))
      if (hashParams.get('state') !== null && hashParams.get('session_state') !== null && hashParams.get('code') !== 'null') {
        // This gets triggered after external SSO auth was successful and user is redirected back to DMRx
        // Redo keycloak-js from env vars
        const keycloak = new Keycloak({
          url: window.env.SSO_IDP_AUTH_URI,
          realm: window.env.SSO_IDP_REALM,
          clientId: window.env.SSO_IDP_CLIENT_ID
        })

        try {
          keycloak.init({})
            .then((authenticated: boolean) => {
              if (authenticated && keycloak.token) {
                // Send token details to the backend authentication API endpoint
                authStore.authCheckSSOToken(keycloak.token, window.env.SSO_IDP_REALM)
                  .then(() => {
                    loginRedirect(authStore.auth, clinicianStore).then((redirectURL) =>
                      navigate(redirectURL)
                    )
                  })
                  .finally(async () => {
                    if (keycloak.idToken) {
                      await authStore.logoutSSOToken(
                        (keycloak.tokenParsed!.iss ?? `${window.env.SSO_IDP_AUTH_URI}/realms/${window.env.SSO_IDP_REALM}`),
                        keycloak.idToken,
                        window.env.SSO_IDP_CLIENT_ID
                      )
                    }

                    keycloak.clearToken()
                  })
              } else {
                appInsights.trackException({
                  'exception': Error('SSO Authentication failed - no token from IdP service'),
                  'severityLevel': SeverityLevel.Warning,
                  'properties': {
                    'errorId': uuidv4(),
                    'errorMessage': JSON.stringify(keycloak),
                    'info': 'SSO DoseMeRx Auth error',
                    'installation': window.env.INSTALLATION
                  }
                })
                setDisplaySSOError(true)
              }
            })
        } catch (error) {
          appInsights.trackException({
            'exception': Error('Check SSO Authentication failed - keycloak adapter error'),
            'severityLevel': SeverityLevel.Warning,
            'properties': {
              'errorId': uuidv4(),
              'errorMessage': JSON.stringify(keycloak),
              'info': `Failed to initialize adapter: ${error}`,
              'installation': window.env.INSTALLATION
            }
          })
          setDisplaySSOError(true)
        }
      }
    } else {
      setDisplaySSOError(true)
    }
  }, [])

  if (displaySSOError) {
    return (
      <div>
        <div className='check-sso-page-panel'>
          <div className='invalid-sso-title'>Oh no! Unable to Check your SSO credentials</div>
          <div className='check-sso-subtitle'>It looks like an error has occurred verifying your SSO credentials with DoseMeRx.</div>
          <div className='invalid-sso-body'>Please close your browser window and try again.</div>
          <div className='invalid-sso-info-bubble'>
            <InfoBubble bubbleTitle='If problems persist, please contact DoseMe Support.' />
          </div>
        </div>
        <div className='w-440'><TOSFooter /></div>
      </div>
    )
  }

  return (
    <div>
      <div className='check-sso-page-panel'>
        <div className='check-sso-title'>Checking SSO credentials...</div>
        <div className='check-sso-subtitle'>Please wait a moment</div>
        <div className='check-sso-loading-spinner'>
          <Icons.ThinSpinner strokeWidth={16} r={30} stroke={LOADING_GREY} width='32px' />
        </div>
      </div>

      <div className='w-440'><TOSFooter /></div>
    </div>
  )
})
