import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsChartUserLoginNumbersStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { UserLoginNumbersPlot } from './components'
import { AnalyticsChart } from '../AnalyticsChart'

export const UserLoginNumbers: React.FC<IBaseChartProps> = observer((props) => {
  const userLoginNumbersChartStore = useAnalyticsChartUserLoginNumbersStore()

  useEffect(() => {
    userLoginNumbersChartStore.fetchAnalyticsChart(
      props.startDate,
      props.endDate,
      props.hospitalIds,
      props.moleculeId,
      props.clinicianFilter
    )
  }, [props.hospitalIds, props.moleculeId, props.clinicianFilter, props.startDate, props.endDate])

  return (
    <AnalyticsChart
      chartStore={userLoginNumbersChartStore}
      plotComponent={UserLoginNumbersPlot}
      title='User login numbers'
      plotOverlayHeight='360px'
    />
  )
})
