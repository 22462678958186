import { AnalyticsPlotSkeleton, InfoBubble, MessageOverlay } from '@doseme/cohesive-ui'
import React from 'react'
import { observer } from 'mobx-react-lite'

import { IAnalyticsChartProps } from '../types'

import '../index.scss'

export const AnalyticsChart: React.FC<IAnalyticsChartProps> = observer(
  ({ plotOverlayWidth = '924px', plotOverlayHeight = '284px', ...props }) => {
    const key = props.title.toLowerCase().replace(/\s+/g, '-')

    const renderPlotContent = () => {
      if (['loadError', 'updateError'].includes(props.chartStore.loadState)) {
        return (
          <MessageOverlay
            type='error'
            headerText='Failed to load analytics chart'
            messageText={<div className='panel-error-overlay-text'>{props.chartStore.error} || Something went wrong</div>}
            showBlur={false}
          >
            <AnalyticsPlotSkeleton
              loading={false}
              style={{ width: plotOverlayWidth, height: plotOverlayHeight }}
            />
          </MessageOverlay>
        )
      }

      if (['loading', 'updating'].includes(props.chartStore.loadState)) {
        return (
          <AnalyticsPlotSkeleton
            loading={true}
            style={{ width: plotOverlayWidth, height: plotOverlayHeight }}
          />
        )
      }

      if (props.chartStore.loadState === 'loaded' && !props.chartStore.hasPlotData()) {
        return (
          <MessageOverlay
            type='warning'
            headerText='No relevant data found'
            messageText={
              <div>
                No data found matching your query.
                <br/>
                Try adjusting the date range or any filters applied.
              </div>
            }
            showBlur={false}
          >
            <AnalyticsPlotSkeleton
              loading={false}
              style={{ width: plotOverlayWidth, height: plotOverlayHeight }}
            />
          </MessageOverlay>
        )
      }

      if (props.chartStore.analyticsChartData) {
        return (
          <div className='doseme-analytics-chart-plot'>
            <props.plotComponent
              plotData={props.chartStore.analyticsChartData.attributes.plotData.dataPoints}
              plotSeries={props.chartStore.analyticsChartData.attributes.plotData.plotSeries}
              plotName={props.chartStore.analyticsChartData.attributes.name}
            />
          </div>
        )
      }
    }

    return (
      <div className={`doseme-analytics-chart ${props.chartCssClass}`} key={key}>
        <div className='header-row'>
          <div className={`title ${props.panelColWidth ? props.panelColWidth : 'md-8'}`}>{props.title}</div>
          { props.viewOptionsContent &&
              <div className={`view-options ${props.infoBubbleContent ? 'md-3' : 'md-4'}`}>
                {/*<ListButton disabled={true}>*/}
                {/*  <FontAwesomeIcon icon={faCog} />&nbsp;View Options*/}
                {/* props.viewOptionsContent */}
                {/*</ListButton>*/}
              </div>
          }
        </div>
        {renderPlotContent()}
        { props.infoBubbleContent &&
          <div className='info-bubble-icon md-1'>
            <InfoBubble type='info' bubbleTitle={'Note:'} borderRadius={6}>
              { props.infoBubbleContent }
            </InfoBubble>
          </div>
        }
      </div>
    )
  }
)
