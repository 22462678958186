import { IMenuItem } from '@doseme/cohesive-ui'
import { decode } from 'he'

import { IFilterItem, IFilterResourceListItem, IFilterSubItem } from '../../../../store/types'

export const buildFilterItems = (
  analyticsFilterData: IFilterItem[],
  updateFilter: (
    type: string,
    selected: boolean,
    item: IFilterResourceListItem,
  ) => void,
  clinicianFilter: Record<string, string>,
  drugFilter: Record<string, string>
): IMenuItem[] => {
  return analyticsFilterData.map((analyticsFilterItem: IFilterItem) => {
    let selectedCount

    switch (analyticsFilterItem.attributes.name) {
      case 'drug':
        selectedCount = Object.keys(drugFilter).length
        break
      case 'clinician':
        selectedCount = Object.keys(clinicianFilter).length
        break
    }

    return {
      id: analyticsFilterItem.id,
      name: decode(analyticsFilterItem.attributes.label),
      subItems: buildSubItems(
        analyticsFilterItem.attributes.values,
        updateFilter,
        clinicianFilter,
        drugFilter,
        analyticsFilterItem.attributes.name
      ),
      selectedCount: selectedCount ? selectedCount : undefined
    }
  })
}

const buildSubItems = (
  subItems: Array<IFilterResourceListItem | IFilterSubItem>,
  updateFilter: (
    type: string,
    selected: boolean,
    item: IFilterResourceListItem,
  ) => void,
  clinicianFilter: Record<string, string>,
  drugFilter: Record<string, string>,
  type: string
): IMenuItem[] => {

  return subItems.map<IMenuItem>((subItem: any) => {
    let itemSelected = false
    let selectedCount = 0

    switch (type) {
      case 'drug':
        itemSelected = drugFilter[subItem.id] !== undefined
        break
      case 'clinician':
        itemSelected = clinicianFilter[subItem.id] !== undefined
        break
    }

    return {
      id: subItem.id,
      name: decode(subItem.name),
      selected: itemSelected,
      onSelect: subItem.values ? undefined : (selected: boolean) => updateFilter(type, selected, subItem),
      selectedCount: selectedCount ? selectedCount : undefined
    }
  })
}
