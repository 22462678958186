import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsChartChangeOverTimeToTherapeuticTargetStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { ChangeOverTimeToTherapeuticTargetPlot } from './components'
import { AnalyticsChart } from '../AnalyticsChart'

export const ChangeOverTimeToTherapeuticTarget: React.FC<IBaseChartProps> = observer((props) => {
  const analyticsChartChangeOverTimeToTherapeuticTargetStore = useAnalyticsChartChangeOverTimeToTherapeuticTargetStore()

  useEffect(() => {
    analyticsChartChangeOverTimeToTherapeuticTargetStore.fetchAnalyticsChart()
  }, [props.hospitalIds, props.moleculeId, props.clinicianFilter, props.drugFilter, props.startDate, props.endDate])

  return (
    <AnalyticsChart
      chartStore={analyticsChartChangeOverTimeToTherapeuticTargetStore}
      plotComponent={ChangeOverTimeToTherapeuticTargetPlot}
      title='Change Over Time'
    />
  )
})
