import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsChartCourseTimeToTherapeuticTargetStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { CourseTimeToTherapeuticTargetPlot } from './components'
import { AnalyticsChart } from '../AnalyticsChart'

export const CourseTimeToTherapeuticTarget: React.FC<IBaseChartProps> = observer((props) => {
  const courseTimeToTherapeuticTargetChartStore = useAnalyticsChartCourseTimeToTherapeuticTargetStore()

  useEffect(() => {
    courseTimeToTherapeuticTargetChartStore.fetchAnalyticsChart()
  }, [props.hospitalIds, props.moleculeId, props.clinicianFilter, props.drugFilter, props.startDate, props.endDate])

  return (
    <AnalyticsChart
      chartStore={courseTimeToTherapeuticTargetChartStore}
      plotComponent={CourseTimeToTherapeuticTargetPlot}
      title='Course Time to Therapeutic Target by drug model'
      plotOverlayWidth='546px'
      plotOverlayHeight='266px'
    />
  )
})
