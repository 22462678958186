import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsChartDailyUsageStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { DailyUsagePlot } from './components'
import { AnalyticsChart } from '../AnalyticsChart'

export const DailyUsage: React.FC<IBaseChartProps> = observer((props) => {
  const dailyUsageChartStore = useAnalyticsChartDailyUsageStore()

  useEffect(() => {
    dailyUsageChartStore.fetchAnalyticsChart(
      props.startDate,
      props.endDate,
      props.hospitalIds,
      props.moleculeId,
      props.clinicianFilter
    )
  }, [props.hospitalIds, props.moleculeId, props.clinicianFilter, props.startDate, props.endDate])

  return (
    <AnalyticsChart
      chartStore={dailyUsageChartStore}
      plotComponent={DailyUsagePlot}
      title='Daily total usage trend'
    />
  )
})
