import { configure } from 'mobx'

import { AnalyticsChartDailyUsageStore } from '../../DoseMeAnalytics/analyticsChart/charts/DailyUsageStore'
import { AnalyticsChartUsageByHourStore } from '../../DoseMeAnalytics/analyticsChart/charts/UsageByHourStore'
import { AnalyticsChartUsageByDayStore } from '../../DoseMeAnalytics/analyticsChart/charts/UsageByDayStore'
import { AnalyticsChartAKIRiskFactorsStore } from '../../DoseMeAnalytics/analyticsChart/charts/AKIRiskFactorsStore'
import { AnalyticsChartDailyDoseAmountsStore } from '../../DoseMeAnalytics/analyticsChart/charts/DailyDoseAmountsStore'
import { AnalyticsChartDosesSimulatedByUserStore } from '../../DoseMeAnalytics/analyticsChart/charts/DosesSimulatedByUserStore'
import { AnalyticsChartDrugModelUsageBreakdownStore } from '../../DoseMeAnalytics/analyticsChart/charts/DrugModelUsageBreakdownStore'
import { AnalyticsChartDrugModelUsageOverTimeStore } from '../../DoseMeAnalytics/analyticsChart/charts/DrugModelUsageOverTimeStore'
import { AnalyticsChartReportsByTypeStore } from '../../DoseMeAnalytics/analyticsChart/charts/ReportsByTypeStore'
import { AnalyticsChartReportsByUserStore } from '../../DoseMeAnalytics/analyticsChart/charts/ReportsByUserStore'
import { AnalyticsChartTroughOutcomesStore } from '../../DoseMeAnalytics/analyticsChart/charts/TroughOutcomesStore'
import { AnalyticsChartUniqueUsersStore } from '../../DoseMeAnalytics/analyticsChart/charts/UniqueUsersStore'
import { AnalyticsChartUserLoginNumbersStore } from '../../DoseMeAnalytics/analyticsChart/charts/UserLoginNumbersStore'
import { AnalyticsChartCourseTimeToTherapeuticTargetStore } from '../../DoseMeAnalytics/analyticsChart/charts/CourseTimeToTherapeuticTargetStore'
import { AnalyticsChartAucVsTroughOutcomesStore } from '../../DoseMeAnalytics/analyticsChart/charts/AucVsTroughOutcomesStore'
import { AnalyticsChartMedianHoursAndDosesToTargetStore } from '../../DoseMeAnalytics/analyticsChart/charts/MedianHoursAndDosesToTargetStore'
import { AnalyticsChartAucRiskThresholdsStore } from '../../DoseMeAnalytics/analyticsChart/charts/AucRiskThresholdsStore'
import { AnalyticsChartChangeOverTimeToTherapeuticTargetStore } from '../../DoseMeAnalytics/analyticsChart/charts/ChangeOverTimeToTherapeuticTargetStore'
import { AnalyticsFiltersStore } from '../AnalyticsFilters/AnalyticsFiltersStore'
import { ErrorsStore } from '../../errors/ErrorsStore'

configure({
  useProxies: 'never'
})

export class AnalyticsRootStore {
  analyticsChartDailyUsageStore: AnalyticsChartDailyUsageStore
  analyticsChartUsageByHourStore: AnalyticsChartUsageByHourStore
  analyticsChartUsageByDayStore: AnalyticsChartUsageByDayStore
  analyticsChartUniqueUsersStore: AnalyticsChartUniqueUsersStore
  analyticsChartUserLoginNumbersStore: AnalyticsChartUserLoginNumbersStore
  analyticsChartTroughOutcomesStore: AnalyticsChartTroughOutcomesStore
  analyticsChartAKIRiskFactorsStore: AnalyticsChartAKIRiskFactorsStore
  analyticsChartDailyDoseAmountsStore: AnalyticsChartDailyDoseAmountsStore
  analyticsChartDrugModelUsageBreakdownStore: AnalyticsChartDrugModelUsageBreakdownStore
  analyticsChartDrugModelUsageOverTimeStore: AnalyticsChartDrugModelUsageOverTimeStore
  analyticsChartDosesSimulatedByUserStore: AnalyticsChartDosesSimulatedByUserStore
  analyticsChartReportsByTypeStore: AnalyticsChartReportsByTypeStore
  analyticsChartReportsByUserStore: AnalyticsChartReportsByUserStore
  analyticsChartCourseTimeToTherapeuticTargetStore: AnalyticsChartCourseTimeToTherapeuticTargetStore
  analyticsChartAucVsTroughOutcomesStore: AnalyticsChartAucVsTroughOutcomesStore
  analyticsChartMedianHoursAndDosesToTargetStore: AnalyticsChartMedianHoursAndDosesToTargetStore
  analyticsChartAucRiskThresholdsStore: AnalyticsChartAucRiskThresholdsStore
  analyticsChartChangeOverTimeToTherapeuticTargetStore: AnalyticsChartChangeOverTimeToTherapeuticTargetStore
  analyticsFiltersStore: AnalyticsFiltersStore
  analyticsErrorStore: ErrorsStore

  resetOnLogout() {
    this.analyticsChartDailyUsageStore.resetStore()
    this.analyticsChartUsageByHourStore.resetStore()
    this.analyticsChartUsageByDayStore.resetStore()
    this.analyticsChartUniqueUsersStore.resetStore()
    this.analyticsChartUserLoginNumbersStore.resetStore()
    this.analyticsChartTroughOutcomesStore.resetStore()
    this.analyticsChartAKIRiskFactorsStore.resetStore()
    this.analyticsChartDailyDoseAmountsStore.resetStore()
    this.analyticsChartDrugModelUsageBreakdownStore.resetStore()
    this.analyticsChartDrugModelUsageOverTimeStore.resetStore()
    this.analyticsChartDosesSimulatedByUserStore.resetStore()
    this.analyticsChartReportsByTypeStore.resetStore()
    this.analyticsChartReportsByUserStore.resetStore()
    this.analyticsChartCourseTimeToTherapeuticTargetStore.resetStore()
    this.analyticsChartAucVsTroughOutcomesStore.resetStore()
    this.analyticsChartMedianHoursAndDosesToTargetStore.resetStore()
    this.analyticsChartAucRiskThresholdsStore.resetStore()
    this.analyticsChartChangeOverTimeToTherapeuticTargetStore.resetStore()
    this.analyticsFiltersStore.resetStore()
    this.analyticsErrorStore.resetStore()
  }

  constructor() {
    this.analyticsChartDailyUsageStore = new AnalyticsChartDailyUsageStore(this)
    this.analyticsChartUsageByHourStore = new AnalyticsChartUsageByHourStore(this)
    this.analyticsChartUsageByDayStore = new AnalyticsChartUsageByDayStore(this)
    this.analyticsChartUniqueUsersStore = new AnalyticsChartUniqueUsersStore(this)
    this.analyticsChartUserLoginNumbersStore = new AnalyticsChartUserLoginNumbersStore(this)
    this.analyticsChartTroughOutcomesStore = new AnalyticsChartTroughOutcomesStore(this)
    this.analyticsChartAKIRiskFactorsStore = new AnalyticsChartAKIRiskFactorsStore(this)
    this.analyticsChartDailyDoseAmountsStore = new AnalyticsChartDailyDoseAmountsStore(this)
    this.analyticsChartDrugModelUsageBreakdownStore = new AnalyticsChartDrugModelUsageBreakdownStore(this)
    this.analyticsChartDrugModelUsageOverTimeStore = new AnalyticsChartDrugModelUsageOverTimeStore(this)
    this.analyticsChartDosesSimulatedByUserStore = new AnalyticsChartDosesSimulatedByUserStore(this)
    this.analyticsChartReportsByTypeStore = new AnalyticsChartReportsByTypeStore(this)
    this.analyticsChartReportsByUserStore = new AnalyticsChartReportsByUserStore(this)
    this.analyticsChartCourseTimeToTherapeuticTargetStore = new AnalyticsChartCourseTimeToTherapeuticTargetStore(this)
    this.analyticsChartAucVsTroughOutcomesStore = new AnalyticsChartAucVsTroughOutcomesStore(this)
    this.analyticsChartMedianHoursAndDosesToTargetStore = new AnalyticsChartMedianHoursAndDosesToTargetStore(this)
    this.analyticsChartAucRiskThresholdsStore = new AnalyticsChartAucRiskThresholdsStore(this)
    this.analyticsChartChangeOverTimeToTherapeuticTargetStore = new AnalyticsChartChangeOverTimeToTherapeuticTargetStore(this)
    this.analyticsFiltersStore = new AnalyticsFiltersStore(this)
    this.analyticsErrorStore = new ErrorsStore(this)
  }
}
