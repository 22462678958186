import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsChartUsageByDayStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { UsageByDayPlot } from './components'
import { AnalyticsChart } from '../AnalyticsChart'

export const UsageByDay: React.FC<IBaseChartProps> = observer((props) => {
  const usageByDayChartStore = useAnalyticsChartUsageByDayStore()

  useEffect(() => {
    usageByDayChartStore.fetchAnalyticsChart(
      props.startDate,
      props.endDate,
      props.hospitalIds,
      props.moleculeId,
      props.clinicianFilter
    )
  }, [props.hospitalIds, props.moleculeId, props.clinicianFilter, props.startDate, props.endDate])

  return (
    <AnalyticsChart
      chartStore={usageByDayChartStore}
      plotComponent={UsageByDayPlot}
      title='Usage by day of the week'
      plotOverlayHeight='300px'
    />
  )
})
