import { makeObservable, override } from 'mobx'

import { aucVsTroughOutcomesMockData } from '../../../../components/DoseMeAnalytics/components/AucVsTroughOutcomes/mockData'
import { analyticsChartsAKIRiskFactorsGetUrl } from '../../../../constants/api'
import { AnalyticsRootStore } from '../../AnalyticsRootStore/AnalyticsRootStore'
import { AnalyticsChartStore } from '../AnalyticsChartsStore'

export class AnalyticsChartAucVsTroughOutcomesStore extends AnalyticsChartStore {
  //FIXME - IFE-1316 Remove once endpoint is implemented. Will use the fetchAnalyticsChart in AnalyticsChartStore
  async fetchAnalyticsChart() {
    this.setLoadState('loading')

    this.setAnalyticsChart(aucVsTroughOutcomesMockData)
    this.setLoadState('loaded')
  }

  constructor(rootStore: AnalyticsRootStore) {
    super(rootStore, analyticsChartsAKIRiskFactorsGetUrl())
    makeObservable(this, {
      fetchAnalyticsChart: override
    })
  }
}
