import { useContext } from 'react'

import {
  AnalyticsRootStoreContext,
  AnalyticsChartDailyUsageStoreContext,
  AnalyticsChartUsageByHourStoreContext,
  AnalyticsChartUsageByDayStoreContext,
  AnalyticsChartUniqueUsersStoreContext,
  AnalyticsChartUserLoginNumbersStoreContext,
  AnalyticsChartTroughOutcomesStoreContext,
  AnalyticsChartAKIRiskFactorsStoreContext,
  AnalyticsChartDailyDoseAmountsStoreContext,
  AnalyticsChartDrugModelUsageBreakdownStoreContext,
  AnalyticsChartDrugModelUsageOverTimeStoreContext,
  AnalyticsChartDosesSimulatedByUserStoreContext,
  AnalyticsChartReportsByTypeStoreContext,
  AnalyticsChartReportsByUserStoreContext,
  AnalyticsChartCourseTimeToTherapeuticTargetContext,
  AnalyticsErrorStoreContext,
  AnalyticsChartAucVsTroughOutcomesStoreContext,
  AnalyticsChartAucRiskThresholdsStoreContext,
  AnalyticsChartMedianHoursAndDosesToTargetStoreContext,
  AnalyticsChartChangeOverTimeToTherapeuticTargetStoreContext,
  AnalyticsFiltersStoreContext
} from '../store/DoseMeAnalytics/AnalyticsRootStore/AnalyticsStoreProvider'
import {
  RootStoreContext,
  ErrorsStoreContext,
  AuthStoreContext,
  PasswordRulesStoreContext,
  HospitalStoreContext,
  PatientStoreContext,
  PatientExternalDataStoreContext,
  PatientListStoreContext,
  CourseFeaturesStoreContext,
  CourseStoreContext,
  PatientCoursesStoreContext,
  AdministrationsStoreContext,
  ObservationsStoreContext,
  DrugModelsStoreContext,
  HistoricalSimulationStoreContext,
  DosingRecommendationStoreContext,
  ActivityLogStoreContext,
  GenerateReportStoreContext,
  DrugModelsInformationStoreContext,
  DosingMatrixStoreContext,
  VersionsStoreContext,
  AdminHospitalListStoreContext,
  AdminVendorListStoreContext,
  AdminVendorDetailStoreContext,
  AdminVendorSiteDetailStoreContext,
  AdminVendorSiteListStoreContext,
  AdminHospitalDrugListStoreContext,
  AdminHospitalDetailsStoreContext,
  ConstantsTimezoneStoreContext,
  ApplicableDrugModelsStoreContext,
  ClinicianStoreContext,
  AdminClinicianListStoreContext,
  AdminClinicianDetailsStoreContext,
  AdminClinicianSecurityStoreContext,
  AdminHospitalDrugDetailsStoreContext,
  AnalyticsStoreContext,
  AdminChangeLogListStoreContext
} from '../store/StoreProvider'

export const useStore = () => useContext(RootStoreContext)
export const useErrorsStore = () => useContext(ErrorsStoreContext)
export const useAuthStore = () => useContext(AuthStoreContext)
export const usePasswordRulesStore = () => useContext(PasswordRulesStoreContext)
export const useHospitalStore = () => useContext(HospitalStoreContext)
export const usePatientStore = () => useContext(PatientStoreContext)
export const usePatientExternalDataStore = () => useContext(PatientExternalDataStoreContext)
export const usePatientListStore = () => useContext(PatientListStoreContext)
export const useCourseFeaturesStore = () => useContext(CourseFeaturesStoreContext)
export const useCourseStore = () => useContext(CourseStoreContext)
export const usePatientCoursesStore = () => useContext(PatientCoursesStoreContext)
export const useAdministrationsStore = () => useContext(AdministrationsStoreContext)
export const useObservationsStore = () => useContext(ObservationsStoreContext)
export const useDrugModelsStore = () => useContext(DrugModelsStoreContext)
export const useHistoricalSimulationStore = () => useContext(HistoricalSimulationStoreContext)
export const useDosingRecommendationStore = () => useContext(DosingRecommendationStoreContext)
export const useActivityLogStore = () => useContext(ActivityLogStoreContext)
export const useGenerateReportStore = () => useContext(GenerateReportStoreContext)
export const useDrugModelsInformationStore = () => useContext(DrugModelsInformationStoreContext)
export const useDosingMatrixStore = () => useContext(DosingMatrixStoreContext)
export const useVersionsStore = () => useContext(VersionsStoreContext)
export const useAdminHospitalListStore = () => useContext(AdminHospitalListStoreContext)
export const useAdminVendorListStore = () => useContext(AdminVendorListStoreContext)
export const useAdminVendorDetailsStore = () => useContext(AdminVendorDetailStoreContext)
export const useAdminVendorSiteDetailsStore = () => useContext(AdminVendorSiteDetailStoreContext)
export const useAdminVendorSiteListStore = () => useContext(AdminVendorSiteListStoreContext)
export const useAdminHospitalDrugListStore = () => useContext(AdminHospitalDrugListStoreContext)
export const useConstantsTimezoneStore = () => useContext(ConstantsTimezoneStoreContext)
export const useApplicableDrugModelsStore = () => useContext(ApplicableDrugModelsStoreContext)
export const useAdminHospitalDetailsStore = () => useContext(AdminHospitalDetailsStoreContext)
export const useClinicianStore = () => useContext(ClinicianStoreContext)
export const useAdminClinicianListStore = () => useContext(AdminClinicianListStoreContext)
export const useAdminClinicianDetailsStore = () => useContext(AdminClinicianDetailsStoreContext)
export const useAdminChangeLogListStore = () => useContext(AdminChangeLogListStoreContext)
export const useAdminClinicianSecurityStore = () => useContext(AdminClinicianSecurityStoreContext)
export const useAdminHospitalDrugDetailsStore = () => useContext(AdminHospitalDrugDetailsStoreContext)
export const useAnalyticsStore = () => useContext(AnalyticsStoreContext)

//Analytics stores
export const useAnalyticsRootStore = () => useContext(AnalyticsRootStoreContext)
export const useAnalyticsChartDailyUsageStore = () => useContext(AnalyticsChartDailyUsageStoreContext)
export const useAnalyticsChartUsageByHourStore = () => useContext(AnalyticsChartUsageByHourStoreContext)
export const useAnalyticsChartUsageByDayStore = () => useContext(AnalyticsChartUsageByDayStoreContext)
export const useAnalyticsChartUserLoginNumbersStore = () => useContext(AnalyticsChartUserLoginNumbersStoreContext)
export const useAnalyticsChartUniqueUsersStore = () => useContext(AnalyticsChartUniqueUsersStoreContext)
export const useAnalyticsChartTroughOutcomesStore = () => useContext(AnalyticsChartTroughOutcomesStoreContext)
export const useAnalyticsChartAKIRiskFactorsStore = () => useContext(AnalyticsChartAKIRiskFactorsStoreContext)
export const useAnalyticsChartDailyDoseAmountsStore = () => useContext(AnalyticsChartDailyDoseAmountsStoreContext)
export const useAnalyticsChartDrugModelUsageBreakdownStore = () => useContext(AnalyticsChartDrugModelUsageBreakdownStoreContext)
export const useAnalyticsChartDrugModelUsageOverTimeStore = () => useContext(AnalyticsChartDrugModelUsageOverTimeStoreContext)
export const useAnalyticsChartDosesSimulatedByUserStore = () => useContext(AnalyticsChartDosesSimulatedByUserStoreContext)
export const useAnalyticsChartReportsByTypeStore = () => useContext(AnalyticsChartReportsByTypeStoreContext)
export const useAnalyticsChartReportsByUserStore = () => useContext(AnalyticsChartReportsByUserStoreContext)
export const useAnalyticsChartCourseTimeToTherapeuticTargetStore = () => useContext(AnalyticsChartCourseTimeToTherapeuticTargetContext)
export const useAnalyticsAucVsTroughOutcomesStore = () => useContext(AnalyticsChartAucVsTroughOutcomesStoreContext)
export const useAnalyticsChartAucRiskThresholdsStore = () => useContext(AnalyticsChartAucRiskThresholdsStoreContext)
export const useAnalyticsChartMedianHoursAndDosesToTargetStore = () => useContext(AnalyticsChartMedianHoursAndDosesToTargetStoreContext)
export const useAnalyticsChartChangeOverTimeToTherapeuticTargetStore = () => useContext(AnalyticsChartChangeOverTimeToTherapeuticTargetStoreContext)
export const useAnalyticsFiltersStore = () => useContext(AnalyticsFiltersStoreContext)
export const useAnalyticsErrorsStore = () => useContext(AnalyticsErrorStoreContext)
