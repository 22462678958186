import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useAnalyticsChartDrugModelUsageOverTimeStore } from '../../../../hooks/useStore'
import { IBaseChartProps } from '../../types'
import { DrugModelUsageOverTimePlot } from './components'
import { AnalyticsChart } from '../AnalyticsChart'

import './index.scss'

export const DrugModelUsageOverTime: React.FC<IBaseChartProps> = observer((props) => {
  const analyticsChartDrugModelUsageOverTimeStore = useAnalyticsChartDrugModelUsageOverTimeStore()

  useEffect(() => {
    analyticsChartDrugModelUsageOverTimeStore.fetchAnalyticsChart(
      props.startDate,
      props.endDate,
      props.hospitalIds,
      props.moleculeId,
      props.clinicianFilter,
      props.drugFilter
    )
  }, [props.hospitalIds, props.moleculeId, props.startDate, props.endDate, props.clinicianFilter, props.drugFilter])

  return (
    <AnalyticsChart
      chartStore={analyticsChartDrugModelUsageOverTimeStore}
      plotComponent={DrugModelUsageOverTimePlot}
      title='Drug model usage over time'
      plotOverlayWidth='576px'
      plotOverlayHeight='240px'
      chartCssClass='drug-model-usage-over-time'
    />
  )
})
