import { makeAutoObservable } from 'mobx'

import { IFilterItem } from '../../types'
import { AnalyticsFiltersStore } from './AnalyticsFiltersStore'

export class AnalyticsFilterItem implements IFilterItem {
  store: AnalyticsFiltersStore

  id?: string
  type: string
  attributes: IFilterItem['attributes']

  constructor(store: AnalyticsFiltersStore, entity: IFilterItem) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
