import { Skeleton } from '@doseme/cohesive-ui'
import { decode } from 'he'
import { Navigate, RouteObject } from 'react-router-dom'

import { AdminHub } from '../components/AdminRouter/components/AdminHub'
import { ChangeLog } from '../components/AdminRouter/components/ChangeLog'
import { ClinicianList } from '../components/AdminRouter/components/ClinicianSettings'
import { ClinicianDetails } from '../components/AdminRouter/components/ClinicianSettings/components/ClinicianDetails'
import { ClinicianInvite } from '../components/AdminRouter/components/ClinicianSettings/components/ClinicianInvite'
import { HospitalDrugDetails } from '../components/AdminRouter/components/DrugSettings/components/HospitalDrugDetails'
import { HospitalDrugSettingsList } from '../components/AdminRouter/components/DrugSettings/components/HospitalDrugSettingsList'
import { DrugSettingsSelectHospitalList } from '../components/AdminRouter/components/DrugSettings/DrugSettingsSelectHospitalsList'
import { HospitalList } from '../components/AdminRouter/components/HospitalSettings'
import { AddHospital } from '../components/AdminRouter/components/HospitalSettings/components/AddHospital'
import { DuplicateHospital } from '../components/AdminRouter/components/HospitalSettings/components/DuplicateHospital'
import { HospitalDetails } from '../components/AdminRouter/components/HospitalSettings/components/HospitalDetails'
import { VendorList } from '../components/AdminRouter/components/VendorSettings'
import { AddVendor } from '../components/AdminRouter/components/VendorSettings/components/AddVendor'
import { VendorSiteList } from '../components/AdminRouter/components/VendorSettings/VendorSites'
import { VendorSiteDetails } from '../components/AdminRouter/components/VendorSettings/VendorSites/components'
import { AddVendorSite } from '../components/AdminRouter/components/VendorSettings/VendorSites/components/AddVendorSite'
import { AnalyticsPage } from '../components/AnalyticsPage'
import { ForgotPasswordPage } from '../components/AuthRouter/components/ForgotPasswordPage'
import { LoginPage } from '../components/AuthRouter/components/LoginPage'
import { ResetPasswordPage } from '../components/AuthRouter/components/ResetPasswordPage'
import { SetPasswordPage } from '../components/AuthRouter/components/SetPasswordPage'
import { TwoFAPage } from '../components/AuthRouter/components/TwoFAPage'
import { TwoFASetupPage } from '../components/AuthRouter/components/TwoFASetupPage'
import { CheckSSOPage } from '../components/AuthRouter/components/CheckSSOPage'
import { AddCourse } from '../components/PatientRouter/components/AddCourse'
import { DoseRecommendation } from '../components/PatientRouter/components/CourseProfile/components/DoseRecommendation'
import { EditPatient } from '../components/PatientRouter/components/EditPatient'
import { PatientProfile } from '../components/PatientRouter/components/PatientProfile'
import { AddPatient } from '../components/PatientsRouter/components/AddPatient/AddPatient'
import { HospitalPatientListPage } from '../components/PatientsRouter/components/PatientList/HospitalPatientListPage'
import { ClinicalCompetencies } from '../components/ResourcesRouter/components/ClinicalCompetencies'
import { ClinicalResources } from '../components/ResourcesRouter/components/ClinicalResources'
import { Compliance } from '../components/ResourcesRouter/components/Compliance'
import { DrugInformation } from '../components/ResourcesRouter/components/DrugInformation'
import { HelpVideos } from '../components/ResourcesRouter/components/HelpVideos'
import { Resources } from '../components/ResourcesRouter/components/Resources'
import { UserGuides } from '../components/ResourcesRouter/components/UserGuides'
import {
  useAuthStore,
  useHospitalStore,
  useClinicianStore,
  usePatientStore,
  useCourseStore,
  useAdminHospitalDetailsStore,
  useAdminHospitalDrugDetailsStore,
  useAdminClinicianDetailsStore,
  useAdminVendorSiteDetailsStore,
  useAdminVendorDetailsStore
} from '../hooks/useStore'
import { AdminClinicianDetailsStore } from '../store/Admin/AdminClinicianDetails/AdminClinicianDetailsStore'
import { IAdminHospitalDetails } from '../store/Admin/AdminHospitalDetails/types'
import { IAdminHospitalDrugDetails } from '../store/Admin/AdminHospitalDrugDetails/types'
import { AdminVendorDetailsStore } from '../store/Admin/AdminVendorDetails/AdminVendorDetailsStore'
import { AdminVendorSiteDetailsStore } from '../store/Admin/AdminVendorSiteDetails/AdminVendorSiteDetailsStore'
import { IMatchCrumb } from './types'
import { addPatientRoutePrefix } from './utils'
import { HelpPage } from '../components/AuthRouter/components/HelpPage'
import { DoseMeAnalytics } from '../components/DoseMeAnalytics'
import { AnalyticsStoreProvider } from '../store/DoseMeAnalytics/AnalyticsRootStore/AnalyticsStoreProvider'

export const buildRedirectRoute = (path: string): RouteObject => {
  const authStore = useAuthStore()
  const clinicianStore = useClinicianStore()

  if (authStore.isAuthenticated() && clinicianStore.clinician) {
    let redirectURL = '/patients'

    if (window.env.VENDOR_MODE !== 'standalone' && !clinicianStore.clinician?.attributes.isSuperAdmin) {
      if (clinicianStore.canViewAdminHub()) {
        redirectURL = '/admin'
      } else if (clinicianStore.canViewAnalyticsHub()) {
        //FIXME: [IFE-1354] Remove temporary route and supportadmin role requirement
        // once new analytics dashboard gets rolled out
        redirectURL = clinicianStore.hasRole('4')
          ? '/dosemeanalytics'
          : '/analytics'
      } else {
        redirectURL = '/resources'
      }
    }

    return (
      {
        path: path,
        element: <Navigate to={{ pathname: redirectURL }} />
      }
    )
  }

  if (
    ['initial', 'loading'].includes(authStore.loadState)
    || ['initial', 'loading', 'updating'].includes(clinicianStore.loadState)
  ) {
    return (
      {
        path: path,
        element: <Skeleton.InitialLoad />
      }
    )
  }

  return (
    {
      path: path,
      element: <Navigate to={{ pathname: '/login' }} />
    }
  )
}

export const buildAuthRoutes = (): RouteObject[] => {
  const authStore = useAuthStore()

  if (authStore.isAuthenticated()) {
    return []
  }

  return [
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/forgot_password',
      element: <ForgotPasswordPage />
    },
    {
      path: '/set_password',
      element: <SetPasswordPage />
    },
    {
      path: '/reset_password',
      element: <ResetPasswordPage />
    },
    {
      path: '/twofasetup',
      element: <TwoFASetupPage />
    },
    {
      path: '/twofa',
      element: <TwoFAPage />
    },
    {
      path: '/help',
      element: <HelpPage />
    },
    {
      path: '/check_sso',
      element: <CheckSSOPage />
    }
  ]
}

export const buildPatientsRoutes = (): RouteObject => {
  const hospitalStore = useHospitalStore()
  const clinicianStore = useClinicianStore()

  const loading: RouteObject[] = [
    {
      path: '/patients',
      element: (
        <div className='h-100'>
          <Skeleton.PatientList />
        </div>
      )
    },
    {
      path: '/patients/:patientId/courses/:courseId',
      element: (
        <div className='h-100'>
          <Skeleton.DoseRecommendation />
        </div>
      )
    },
    {
      path: '/patients/:patientId',
      element: (
        <div className='h-100'>
          <Skeleton.PatientProfile showConnectionStatus={window.env.APP_MODE === 'integrated'} />
        </div>
      )
    },
    {
      path: '/patients/:patientId/*',
      element: (
        <div className='h-100'>
          <Skeleton.PatientProfile showConnectionStatus={window.env.APP_MODE === 'integrated'} />
        </div>
      )
    },
    {
      path: '/patients/*',
      element: (
        <div className='h-100'>
          <Skeleton.PatientList />
        </div>
      )
    }
  ]

  const redirect: RouteObject[] = [
    {
      path: '/patients',
      element: (
        <Navigate to={{ pathname: '/login' }} />
      )
    },
    {
      path: '/patients/*',
      element: (
        <Navigate to={{ pathname: '/login' }} />
      )
    }
  ]

  const children = [
    {
      path: '/patients',
      element: <HospitalPatientListPage />
    },
    {
      path: '/patients/new',
      handle: {
        crumb: {
          label: () => 'Add new patient',
          path: () => '/patients/new'
        } as IMatchCrumb
      },
      element: <AddPatient />
    },
    buildPatientRoutes()
  ]

  return {
    path: '/patients',
    loader: () => null,
    handle: {
      crumb: {
        label: () => `Patients at ${decode(hospitalStore.hospital?.attributes.name || 'hospital')}`,
        loading: !hospitalStore.hospital || !clinicianStore.clinician
          || ['initial', 'loading'].includes(hospitalStore.loadState)
          || ['initial', 'loading'].includes(clinicianStore.loadState),
        path: () => '/patients'
      } as IMatchCrumb
    },
    children: (
      ['initial', 'loading'].includes(hospitalStore.loadState)
      || ['initial', 'loading'].includes(clinicianStore.loadState)
    )
      ? loading
      : !(window.env.APP_MODE === 'integrated'
        || (window.env.VENDOR_MODE !== 'standalone' && !clinicianStore.clinician?.attributes.isSuperAdmin))
        ? children
        : redirect
  }
}

export const buildPatientRoutes = (): RouteObject => {
  const clinicianStore = useClinicianStore()
  const hospitalStore = useHospitalStore()
  const patientStore = usePatientStore()
  const courseStore = useCourseStore()

  // FIXME-1237 - It would be worth trying to conditiontally add the initial loading page for
  // integrated instances here as well

  const loading: RouteObject[] = [
    {
      path: '/patients/:patientId',
      element: (
        <div className='h-100'>
          <Skeleton.PatientProfile showConnectionStatus={window.env.APP_MODE === 'integrated'} />
        </div>
      )
    },
    {
      path: '/patients/:patientId/*',
      element: (
        <div className='h-100'>
          <Skeleton.PatientProfile showConnectionStatus={window.env.APP_MODE === 'integrated'} />
        </div>
      )
    }
  ]

  const children = [
    {
      path: '/patients/:patientId',
      element: <PatientProfile />
    },
    {
      path: '/patients/:patientId/edit',
      handle: {
        crumb: {
          label: () => 'Edit patient details',
          path: () => `/patients/${patientStore.patient?.id}/edit`
        } as IMatchCrumb
      },
      element: <EditPatient />
    },
    {
      path: '/patients/:patientId/courses/new',
      handle: {
        crumb: {
          label: () => 'Create new course',
          path: () => `/patients/${patientStore.patient?.id}/courses/new`
        } as IMatchCrumb
      },
      element: <AddCourse />
    },
    {
      path: '/patients/:patientId/courses/:courseId',
      handle: {
        crumb: {
          label: () => `${decode(courseStore.course?.attributes.drugModel.name || '')}`,
          loading: !courseStore.course || ['loading', 'initial'].includes(courseStore.loadState),
          path: () => `/patients/${patientStore.patient?.id}/courses/${courseStore.course?.id}`
        } as IMatchCrumb
      },
      element: <DoseRecommendation />
    },
    buildResourcesRoutes(true),
    buildAdminRoutes(true)
  ]

  if (clinicianStore.canViewAnalyticsHub()) {
    children.push(...[
      buildAnalyticsRoutes(true)
    ])

    //FIXME: [IFE-1354] Remove supportadmin role requirement once new analytics dashboard gets rolled out
    if (clinicianStore.hasRole('4')) {
      children.push(...[
        buildDoseMeAnalyticsRoutes(true)
      ])
    }
  }

  return {
    path: '/patients/:patientId',
    loader: () => null,
    handle: {
      crumb: {
        label: () => {
          if (patientStore.patient) {
            return `${decode(patientStore.patient?.attributes.familyName || '')}, ${decode(patientStore.patient?.attributes.givenNames || '')} (${patientStore.patient?.attributes.longId || ''})`
          }

          return ''
        },
        loading: !patientStore.patient || ['loading', 'initial'].includes(patientStore.loadState) || !patientStore.patient,
        path: () => `/patients/${patientStore.patient?.id}`
      } as IMatchCrumb
    },
    children: (
      ['initial', 'loading'].includes(hospitalStore.loadState)
      || ['initial', 'loading'].includes(clinicianStore.loadState)
    )
      ? loading
      : !(
        window.env.APP_MODE === 'standalone' && window.env.VENDOR_MODE !== 'standalone'
        && !clinicianStore.clinician?.attributes.isSuperAdmin
      )
        ? children
        : undefined
  }
}

export const buildResourcesRoutes = (isPatientSubRoute: boolean): RouteObject => {
  const hospitalStore = useHospitalStore()

  const loading: RouteObject[] = [
    {
      path: addPatientRoutePrefix('/resources', isPatientSubRoute),
      element: (
        <div className='h-100'>
          <Skeleton.InitialLoad />
        </div>
      )
    },
    {
      path: addPatientRoutePrefix('/resources/*', isPatientSubRoute),
      element: (
        <div className='h-100'>
          <Skeleton.InitialLoad />
        </div>
      )
    }
  ]

  const children: RouteObject[] = [
    {
      path: addPatientRoutePrefix('/resources', isPatientSubRoute),
      element: <Resources />
    },
    {
      path: addPatientRoutePrefix('/resources/help-videos', isPatientSubRoute),
      handle: {
        crumb: {
          label: () => 'Help videos',
          path: () => addPatientRoutePrefix('/resources/help-videos', isPatientSubRoute)
        } as IMatchCrumb
      },
      element: <HelpVideos />
    },
    {
      path: addPatientRoutePrefix('/resources/user-guides', isPatientSubRoute),
      handle: {
        crumb: {
          label: () => 'User guides',
          path: () => addPatientRoutePrefix('/resources/user-guides', isPatientSubRoute)
        } as IMatchCrumb
      },
      element: <UserGuides />
    },
    {
      path: addPatientRoutePrefix('/resources/clinical-competencies', isPatientSubRoute),
      handle: {
        crumb: {
          label: () => 'Clinical competencies',
          path: () => addPatientRoutePrefix('/resources/clinical-competencies', isPatientSubRoute)
        } as IMatchCrumb
      },
      element: <ClinicalCompetencies />
    },
    {
      path: addPatientRoutePrefix('/resources/clinical-resources', isPatientSubRoute),
      handle: {
        crumb: {
          label: () => 'Clinical resources',
          path: () => addPatientRoutePrefix('/resources/clinical-resources', isPatientSubRoute)
        } as IMatchCrumb
      },
      element: <ClinicalResources />
    }
  ]

  if (hospitalStore.loadState === 'loaded' && hospitalStore.hospital?.id) {
    children.push({
      path: addPatientRoutePrefix('/resources/drug-information', isPatientSubRoute),
      handle: {
        crumb: {
          label: () => 'Drug information',
          path: () => addPatientRoutePrefix('/resources/drug-information', isPatientSubRoute)
        } as IMatchCrumb
      },
      element: <DrugInformation hospitalId={hospitalStore.hospital?.id} />
    })
  }

  if (window.env.VENDOR_MODE === 'standalone') {
    children.push({
      path: addPatientRoutePrefix('/resources/compliance', isPatientSubRoute),
      handle: {
        crumb: {
          label: () => 'Compliance',
          path: () => addPatientRoutePrefix('/resources/compliance', isPatientSubRoute)
        } as IMatchCrumb
      },
      element: <Compliance />
    })
  }

  return {
    path: addPatientRoutePrefix('/resources', isPatientSubRoute),
    handle: {
      crumb: {
        label: () => 'Resources',
        path: () => addPatientRoutePrefix('/resources', isPatientSubRoute),
        topLevel: true
      } as IMatchCrumb
    },
    children: hospitalStore.loadState === 'initial' || hospitalStore.loadState === 'loading'
      ? loading
      : children
  }
}

export const buildAnalyticsRoutes = (isPatientSubRoute: boolean): RouteObject => {
  const clinicianStore = useClinicianStore()
  const hospitalStore = useHospitalStore()

  return {
    path: addPatientRoutePrefix('/analytics', isPatientSubRoute),
    handle: {
      crumb: {
        label: () => {
          return hospitalStore.hospital
            ? `Analytics for ${decode(hospitalStore.hospital.attributes.name)}`
            : 'Analytics'
        },
        loading: ['loading', 'initial'].includes(hospitalStore.loadState),
        path: () => addPatientRoutePrefix('/analytics', isPatientSubRoute),
        topLevel: true
      } as IMatchCrumb
    },
    element: ['initial', 'loading', 'updating'].includes(clinicianStore.loadState)
    || ['initial', 'loading', 'updating'].includes(hospitalStore.loadState)
      ? (
        <div className='h-100'>
          <Skeleton.InitialLoad />
        </div>
      )
      : clinicianStore.canViewAnalyticsHub()
        ? <AnalyticsPage />
        : undefined
  }
}

export const buildDoseMeAnalyticsRoutes = (isPatientSubRoute: boolean): RouteObject => {
  const clinicianStore = useClinicianStore()
  const hospitalStore = useHospitalStore()

  return {
    path: addPatientRoutePrefix('/dosemeanalytics', isPatientSubRoute),
    handle: {
      crumb: {
        label: () => {
          return hospitalStore.hospital
            ? `DoseMe Analytics for ${decode(hospitalStore.hospital.attributes.name)}`
            : 'DoseMe Analytics'
        },
        loading: ['loading', 'initial'].includes(hospitalStore.loadState),
        path: () => addPatientRoutePrefix('/dosemeanalytics', isPatientSubRoute),
        topLevel: true
      } as IMatchCrumb
    },
    element: ['initial', 'loading', 'updating'].includes(clinicianStore.loadState)
    || ['initial', 'loading', 'updating'].includes(hospitalStore.loadState)
      ? (
        <div className='h-100'>
          <Skeleton.InitialLoad />
        </div>
      )
      : clinicianStore.canViewAnalyticsHub()
        ? (
          <AnalyticsStoreProvider>
            <DoseMeAnalytics />
          </AnalyticsStoreProvider>
        )
        : undefined
  }
}

export const buildAdminRoutes = (isPatientSubRoute: boolean): RouteObject => {
  const clinicianStore = useClinicianStore()
  const hospitalStore = useHospitalStore()
  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()
  const adminHospitalDrugDetailsStore = useAdminHospitalDrugDetailsStore()
  const adminClinicianDetailsStore = useAdminClinicianDetailsStore()
  const adminVendorDetailsStore = useAdminVendorDetailsStore()
  const adminVendorSiteDetailsStore = useAdminVendorSiteDetailsStore()

  const loading: RouteObject[] = [
    {
      path: addPatientRoutePrefix('/admin', isPatientSubRoute),
      element: (
        <div className='h-100'>
          <Skeleton.InitialLoad />
        </div>
      )
    },
    {
      path: addPatientRoutePrefix('/admin/*', isPatientSubRoute),
      element: (
        <div className='h-100'>
          <Skeleton.InitialLoad />
        </div>
      )
    }
  ]

  const children: RouteObject[] = [
    {
      path: addPatientRoutePrefix('/admin', isPatientSubRoute),
      element: <AdminHub />
    },
    {
      path: addPatientRoutePrefix('/admin/drugsettings', isPatientSubRoute),
      handle: {
        crumb: {
          label: () => 'Drug settings',
          path: () => addPatientRoutePrefix('/admin/drugsettings', isPatientSubRoute)
        } as IMatchCrumb
      },
      element: <DrugSettingsSelectHospitalList />
    },
    {
      path: addPatientRoutePrefix('/admin/changelog', isPatientSubRoute),
      handle: {
        crumb: {
          label: () => 'Change log',
          path: () => addPatientRoutePrefix('/admin/changelog', isPatientSubRoute)
        } as IMatchCrumb
      },
      element: <ChangeLog />
    },
    buildAdminHospitalsRoutes(
      isPatientSubRoute,
      !!clinicianStore.clinician?.attributes.isSuperAdmin,
      adminHospitalDetailsStore.adminHospitalDetails,
      adminHospitalDrugDetailsStore.adminHospitalDrugDetails,
      ['loading', 'initial'].includes(adminHospitalDetailsStore.loadState),
      ['loading', 'initial'].includes(adminHospitalDrugDetailsStore.loadState)
    ),
    buildAdminCliniciansRoutes(isPatientSubRoute, adminClinicianDetailsStore)
  ]

  if (clinicianStore.clinician?.attributes.isSuperAdmin) {
    children.push(buildAdminVendorsRoutes(
      isPatientSubRoute,
      adminVendorDetailsStore,
      adminVendorSiteDetailsStore
    ))
  }

  return {
    path: addPatientRoutePrefix('/admin', isPatientSubRoute),
    loader: () => null,
    handle: {
      crumb: {
        label: () => 'Admin hub',
        path: () => addPatientRoutePrefix('/admin', isPatientSubRoute),
        topLevel: true,
        loading: !hospitalStore.hospital || !clinicianStore.clinician
          || ['initial', 'loading', 'updating'].includes(clinicianStore.loadState)
          || ['initial', 'loading', 'updating'].includes(hospitalStore.loadState)
      } as IMatchCrumb
    },
    children: ['initial', 'loading', 'updating'].includes(clinicianStore.loadState)
      ? loading
      : clinicianStore.canViewAdminHub()
        ? children
        : undefined
  }
}

const buildAdminHospitalsRoutes = (
  isPatientSubRoute: boolean,
  isSuperAdmin: boolean,
  adminHospitalDetails: IAdminHospitalDetails | null,
  adminHospitalDrugDetails: IAdminHospitalDrugDetails | null,
  adminHospitalDetailsLoading: boolean,
  adminHospitalDrugDetailsLoading: boolean
): RouteObject => {
  const children: RouteObject[] = [
    {
      path: addPatientRoutePrefix('/admin/hospitals', isPatientSubRoute),
      element: <HospitalList />
    },
    {
      path: addPatientRoutePrefix('/admin/hospitals/:hospitalId', isPatientSubRoute),
      children: [
        {
          path: addPatientRoutePrefix('/admin/hospitals/:hospitalId', isPatientSubRoute),
          handle: {
            crumb: {
              label: () => decode(adminHospitalDetails?.attributes.details.name || ''),
              loading: !adminHospitalDetails || adminHospitalDetailsLoading,
              path: () => addPatientRoutePrefix(`/admin/hospitals/${adminHospitalDetails?.id}`, isPatientSubRoute)
            } as IMatchCrumb
          },
          element: <HospitalDetails />
        },
        buildAdminHospitalDrugsRoutes(
          isPatientSubRoute,
          adminHospitalDetails,
          adminHospitalDrugDetails,
          adminHospitalDetailsLoading,
          adminHospitalDrugDetailsLoading
        )
      ]
    }
  ]

  if (isSuperAdmin) {
    children.push(
      {
        path: addPatientRoutePrefix('/admin/hospitals/new', isPatientSubRoute),
        handle: {
          crumb: {
            label: () => 'Create new hospital',
            path: () => addPatientRoutePrefix('/admin/hospitals/new', isPatientSubRoute)
          } as IMatchCrumb
        },
        element: <AddHospital />
      },
      {
        path: addPatientRoutePrefix('/admin/hospitals/:hospitalId/duplicate', isPatientSubRoute),
        handle: {
          crumb: {
            label: () => 'Duplicate hospital'
          } as IMatchCrumb
        },
        element: <DuplicateHospital />
      }
    )
  }

  return {
    path: addPatientRoutePrefix('/admin/hospitals', isPatientSubRoute),
    loader: () => null,
    handle: {
      crumb: {
        label: () => 'Hospitals',
        path: () => addPatientRoutePrefix('/admin/hospitals', isPatientSubRoute)
      } as IMatchCrumb
    },
    children: children
  }
}

const buildAdminHospitalDrugsRoutes = (
  isPatientSubRoute: boolean,
  adminHospitalDetails: IAdminHospitalDetails | null,
  adminHospitalDrugDetails: IAdminHospitalDrugDetails | null,
  adminHospitalDetailsLoading: boolean,
  adminHospitalDrugDetailsLoading: boolean
): RouteObject => {
  const children: RouteObject[] = [
    {
      path: addPatientRoutePrefix('/admin/hospitals/:hospitalId/drugs', isPatientSubRoute),
      element: <HospitalDrugSettingsList />
    },
    {
      path: addPatientRoutePrefix('/admin/hospitals/:hospitalId/drugs/:drugId', isPatientSubRoute),
      handle: {
        crumb: {
          label: () => decode(adminHospitalDrugDetails?.attributes.name || ''),
          loading: !adminHospitalDrugDetails || adminHospitalDrugDetailsLoading,
          path: () => addPatientRoutePrefix(
            `/admin/hospitals/${adminHospitalDetails?.id}/drugs/${adminHospitalDrugDetails?.id}`,
            isPatientSubRoute
          )
        } as IMatchCrumb
      },
      element: <HospitalDrugDetails />
    }
  ]

  return {
    path: addPatientRoutePrefix('/admin/hospitals/:hospitalId/drugs', isPatientSubRoute),
    handle: {
      crumb: {
        label: () => decode(adminHospitalDetails?.attributes.details.name || ''),
        loading: !adminHospitalDetails || adminHospitalDetailsLoading,
        path: () => addPatientRoutePrefix(
          `/admin/hospitals/${adminHospitalDetails?.id}/drugs`,
          isPatientSubRoute
        ),
        parentCrumbs: [
          {
            label: () => 'Admin hub',
            path: () => addPatientRoutePrefix('/admin', isPatientSubRoute)
          },
          {
            label: () => 'Drug settings',
            path: () => '/admin/drugsettings'
          }
        ]
      } as IMatchCrumb
    },
    children: children
  }
}

const buildAdminCliniciansRoutes = (
  isPatientSubRoute: boolean,
  adminClinicianDetailsStore: AdminClinicianDetailsStore
): RouteObject => {
  const adminClinicianDetails = adminClinicianDetailsStore.adminClinicianDetails

  const children: RouteObject[] = [
    {
      path: addPatientRoutePrefix('/admin/clinicians', isPatientSubRoute),
      element: <ClinicianList />
    },
    {
      path: addPatientRoutePrefix('/admin/clinicians/new', isPatientSubRoute),
      handle: {
        crumb: {
          label: () => 'Invite new clinician',
          path: () => addPatientRoutePrefix('/admin/clinicians/new', isPatientSubRoute)
        } as IMatchCrumb
      },
      element: <ClinicianInvite />
    },
    {
      path: addPatientRoutePrefix('/admin/clinicians/:clinicianId', isPatientSubRoute),
      loader: ({ params }) => {
        if (['initial', 'loading'].includes(adminClinicianDetailsStore.loadState) && params.clinicianId) {
          adminClinicianDetailsStore.fetchAdminClinicianDetails(params.clinicianId)
        }

        return null
      },
      handle: {
        crumb: {
          label: () => {
            if (adminClinicianDetails?.attributes) {
              const firstName = adminClinicianDetails.attributes.firstName.attributes.currentValue
              const lastname = adminClinicianDetails.attributes.lastName.attributes.currentValue

              return `${decode(firstName || '')} ${decode(lastname || '')}`
            }

            return ''
          },
          loading: !adminClinicianDetails || ['loading', 'initial'].includes(adminClinicianDetailsStore.loadState),
          path: () => addPatientRoutePrefix('/admin/clinicians/:clinicianId', isPatientSubRoute)
        } as IMatchCrumb
      },
      element: <ClinicianDetails />
    }
  ]

  return {
    path: addPatientRoutePrefix('/admin/clinicians', isPatientSubRoute),
    loader: () => null,
    handle: {
      crumb: {
        label: () => 'Clinicians',
        path: () => addPatientRoutePrefix('/admin/clinicians', isPatientSubRoute)
      } as IMatchCrumb
    },
    children: children
  }
}

const buildAdminVendorsRoutes = (
  isPatientSubRoute: boolean,
  adminVendorDetailsStore: AdminVendorDetailsStore,
  adminVendorSiteDetailsStore: AdminVendorSiteDetailsStore
): RouteObject => {
  const adminVendorDetails = adminVendorDetailsStore.adminVendorDetails
  const adminVendorSiteDetails = adminVendorSiteDetailsStore.adminVendorSiteDetails

  const children: RouteObject[] = [
    {
      path: addPatientRoutePrefix('/admin/vendors', isPatientSubRoute),
      element: <VendorList />
    },
    {
      path: addPatientRoutePrefix('/admin/vendors/new', isPatientSubRoute),
      handle: {
        crumb: {
          label: () => 'Create new vendor',
          path: () => addPatientRoutePrefix('/admin/vendors/new', isPatientSubRoute)
        } as IMatchCrumb
      },
      element: <AddVendor />
    },
    {
      path: addPatientRoutePrefix('/admin/vendors/:vendorId/sites', isPatientSubRoute),
      handle: {
        crumb: {
          label: () => decode(adminVendorDetails?.attributes.name.attributes.currentValue || ''),
          loading: !adminVendorDetails || ['loading', 'initial'].includes(adminVendorDetailsStore.loadState),
          path: () => addPatientRoutePrefix(
            `/admin/vendors/${adminVendorDetails?.id}/sites`,
            isPatientSubRoute
          )
        } as IMatchCrumb
      },
      children: [
        {
          path: addPatientRoutePrefix('/admin/vendors/:vendorId/sites', isPatientSubRoute),
          element: <VendorSiteList />
        },
        {
          path: addPatientRoutePrefix('/admin/vendors/:vendorId/sites/new', isPatientSubRoute),
          handle: {
            crumb: {
              label: () => 'Create vendor site',
              path: () => addPatientRoutePrefix(
                `/admin/vendors/${adminVendorDetails?.id}/sites/new`,
                isPatientSubRoute
              )
            } as IMatchCrumb
          },
          element: <AddVendorSite />
        },
        {
          path: addPatientRoutePrefix('/admin/vendors/:vendorId/sites/:vendorSiteId', isPatientSubRoute),
          handle: {
            crumb: {
              label: () => decode(adminVendorSiteDetails?.attributes.name || ''),
              loading: !adminVendorSiteDetails || ['loading', 'initial'].includes(adminVendorSiteDetailsStore.loadState),
              path: () => addPatientRoutePrefix(
                `/admin/vendors/${adminVendorDetails?.id}/sites/${adminVendorSiteDetails?.id}`,
                isPatientSubRoute
              )
            } as IMatchCrumb
          },
          element: <VendorSiteDetails />
        }
      ]
    }
  ]

  return {
    path: addPatientRoutePrefix('/admin/vendors', isPatientSubRoute),
    loader: ({ params }) => {
      if (['loading', 'initial'].includes(adminVendorDetailsStore.loadState) && params.vendorId) {
        adminVendorDetailsStore.fetchAdminVendorDetails(params.vendorId)
      }

      return null
    },
    handle: {
      crumb: {
        label: () => 'Vendors',
        path: () => addPatientRoutePrefix('/admin/vendors', isPatientSubRoute)
      } as IMatchCrumb
    },
    children: children
  }
}
