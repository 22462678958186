import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { IBaseChartProps } from '../../types'
import { ReportsByUserPlot } from './components'
import { useAnalyticsChartReportsByUserStore } from '../../../../hooks/useStore'
import { AnalyticsChart } from '../AnalyticsChart'

export const ReportsByUser: React.FC<IBaseChartProps> = observer((props) => {
  const analyticsChartReportsByUserStore = useAnalyticsChartReportsByUserStore()

  useEffect(() => {
    analyticsChartReportsByUserStore.fetchAnalyticsChart(
      props.startDate,
      props.endDate,
      props.hospitalIds,
      props.moleculeId,
      props.clinicianFilter,
      props.drugFilter
    )
  }, [props.hospitalIds, props.moleculeId, props.startDate, props.endDate, props.clinicianFilter, props.drugFilter])

  return (
    <AnalyticsChart
      chartStore={analyticsChartReportsByUserStore}
      plotComponent={ReportsByUserPlot}
      title='Reports Generated by User'
      plotOverlayHeight='372px'
    />
  )
})
