import { Auth } from '../../../../store/auth/Auth'
import { ClinicianStore } from '../../../../store/ClinicianStore/ClinicianStore'

export const loginRedirect = async (auth: Auth | null, clinicianStore: ClinicianStore): Promise<string> => {
  if (auth) {
    await clinicianStore.fetchClinician(auth.attributes.clinicianId)
  }

  if (window.env.VENDOR_MODE === 'standalone') {
    return '/patients'
  }

  if (clinicianStore.clinician?.attributes.isSuperAdmin) {
    return '/patients'
  }

  if (clinicianStore.canViewAdminHub()) {
    return '/admin'
  }

  if (clinicianStore.canViewAnalyticsHub()) {
    //FIXME: [IFE-1354] Remove temporary route and supportadmin role requirement
    // once new analytics dashboard gets rolled out
    return clinicianStore.hasRole('4')
      ? '/dosemeanalytics'
      : '/analytics'
  }

  return '/resources'
}
