import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Badge, DatePickerInput, IMenuItem, MultiLevelMenu } from '@doseme/cohesive-ui'
import { decode } from 'he'
import { Tooltip } from 'react-tooltip'

import { IFilterResourceListItem } from '../../../../store/types'
import { useAnalyticsFiltersStore } from '../../../../hooks/useStore'
import { buildFilterItems } from './utils'
import { TDosemeAnalyticsTabs } from '../../types'

import '../../index.scss'

interface IProps {
  moleculeId: string
  hospitalIds: string[]
  tab: TDosemeAnalyticsTabs
  dateRangeStart: Date
  dateRangeEnd: Date
  clinicianFilter: Record<string, string>
  drugFilter: Record<string, string>
  setDateRangeStart: (date: Date) => void
  setDateRangeEnd: (date: Date) => void
  setClinicianFilter: (filter: Record<string, string>) => void
  setDrugFilter: (filter: Record<string, string>) => void
}

export const DoseMeAnalyticsFilters: React.FC<IProps> = observer((props) => {
  const [filterItems, setFilterItems] = useState<IMenuItem[]>([])

  const analyticsFilterStore = useAnalyticsFiltersStore()

  useEffect(() => {
    if (props.moleculeId && props.hospitalIds) {
      analyticsFilterStore.fetchAnalyticsFilters(props.moleculeId, props.hospitalIds)
    }
  }, [props.moleculeId, props.hospitalIds])

  useEffect(() => {
    if (analyticsFilterStore.loadState === 'loaded') {
      const analyticsFilters = buildFilterItems(
        [...analyticsFilterStore.analyticsFilters.values()],
        updateFilter,
        props.clinicianFilter,
        props.drugFilter
      )
      setFilterItems(analyticsFilters)
    }
  }, [props.clinicianFilter, props.drugFilter, analyticsFilterStore.loadState])

  const updateFilter = (
    type: string,
    selected: boolean,
    item: IFilterResourceListItem
  ) => {
    if (type === 'clinician') {
      const newClinicianFilter = { ...props.clinicianFilter }

      //Add
      if (selected) {
        newClinicianFilter[item.id] = item.name
        props.setClinicianFilter(newClinicianFilter)

        return
      }

      //Remove
      delete newClinicianFilter[item.id]
      props.setClinicianFilter(newClinicianFilter)

      return
    }

    if (type === 'drug') {
      const newDrugFilter = { ...props.drugFilter }

      //Add
      if (selected) {
        newDrugFilter[item.id] = item.name
        props.setDrugFilter(newDrugFilter)

        return
      }

      //Remove
      delete newDrugFilter[item.id]
      props.setDrugFilter(newDrugFilter)

      return
    }
  }

  const getFilterCount = () => {
    return filterItems.reduce<number>((acc, curr) => {
      return acc + (curr.selectedCount || 0)
    }, 0)
  }

  const buildFilterBadges = (): JSX.Element[] => {
    let badges: JSX.Element[] = []

    const clinicianBadges = Object.keys(props.clinicianFilter).map((id: string) => {
      const name = props.clinicianFilter[id]

      return (
        <Badge
          key={`clinician-badge-${id}-${name}`}
          id={id}
          title={decode(name)}
          onDismiss={(id: string) => updateFilter('clinician', false, { id: id, name: name })}
        />
      )
    })

    badges = badges.concat(clinicianBadges)

    const drugBadges = Object.keys(props.drugFilter).map((id: string) => {
      const name = props.drugFilter[id]

      if (props.tab === 'platformUsage') {
        return (
          <div key={`drug-model-badge-${id}-${name}`}>
            <div data-tooltip-id={`drug-model-badge-${id}-${name}`} >
              <Badge
                inactive
                id={id}
                title={name}
                onDismiss={(id: string) => updateFilter('drug', false, { id: id, name: name })}
              />
            </div>
            <Tooltip id={`drug-model-badge-${id}-${name}`} place='top'>
              Does not apply to ‘Platform usage’ views.
            </Tooltip>
          </div>
        )
      }

      return (
        <Badge
          key={`drug-model-badge-${id}-${name}`}
          id={id}
          title={name}
          onDismiss={(id: string) => updateFilter('drug', false, { id: id, name: name })}
        />
      )
    })

    return badges = badges.concat(drugBadges)
  }

  return (
    <div className='doseme-analytics-panel mb-3'>
      <div className='data-filters-title'>Data filters</div>
      <div className='d-flex'>
        <div className='data-filters-date-range'>
          <div>
            <DatePickerInput
              label='Date range'
              fieldState='valid'
              value={props.dateRangeStart}
              maxDate={props.dateRangeEnd}
              onChange={(value) => props.setDateRangeStart(value)}
              showIcon={false}
            />
          </div>
          <div className='end-date-range'>
            <DatePickerInput
              fieldState='valid'
              value={props.dateRangeEnd}
              minDate={props.dateRangeStart}
              maxDate={new Date()}
              onChange={(value) => props.setDateRangeEnd(value)}
              showIcon={false}
            />
          </div>
        </div>
        <div className='data-filters-context-filters'>
          <div className='patient-list-filter'>
            <MultiLevelMenu
              title='Filters'
              menuItems={filterItems}
              selectedCount={getFilterCount()}
            />
            {buildFilterBadges()}
          </div>
        </div>
      </div>
    </div>
  )
})
